import { FC, Suspense } from 'react'
import { ErrorBoundary } from '@repo/ui/ErrorBoundary'
import ModuleSpinner from '@repo/ui/ModuleSpinner'
import LocalizationGuard from 'src/shared/guards/LocalizationGuard'
import MainCalendar from 'src/modules/CalendarModule'

const CalendarPage: FC = () => {
  return (
    <ErrorBoundary>
      <LocalizationGuard namespace='calendar'>
        <Suspense fallback={<ModuleSpinner />}>
          <MainCalendar />
        </Suspense>
      </LocalizationGuard>
    </ErrorBoundary>
  )
}

export default CalendarPage
