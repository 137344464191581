import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface UseEsc {
  enabled: boolean
  isFormDirty?: boolean
  cb?: () => void
}

const useEsc = ({ enabled, cb, isFormDirty }: UseEsc) => {
  const { t } = useTranslation('common')
  const eventCallback = useCallback(
    (event: KeyboardEvent) => {
      if (!enabled) return

      if (event.code === 'Escape') {
        if (!isFormDirty) {
          cb?.()
        }
        if (isFormDirty && window.confirm(t('confirmUnsavedChangesDialogText'))) {
          cb?.()
        }
      }
    },
    [enabled, cb, isFormDirty],
  )

  useEffect(() => {
    document.addEventListener('keydown', eventCallback)
    return () => {
      document.removeEventListener('keydown', eventCallback)
    }
  }, [eventCallback, isFormDirty])
}

export default useEsc
