import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { FORMATTERS, LITERALS } from '@repo/ui/constants'
import Calendar, { ExtendedEvent } from '@repo/ui/Calendar'

export interface PopoverContentComponentProps<T> {
  event: T | null
  onClose: () => void
}

export const PopoverContentComponent: FC<PopoverContentComponentProps<ExtendedEvent>> = ({ event }) => {
  const { t } = useTranslation('study-plan-create')

  const startTime = event?.start ? format(event.start, FORMATTERS.TIME.TIME) : null
  const endTime = event?.end ? format(event.end, FORMATTERS.TIME.TIME) : null

  return (
    <div className='space-y-2 text-sm'>
      <div className='grid grid-cols-2 gap-x-4'>
        <span className='font-medium text-gray-500'>{t('eventDate')}</span>
        <span>{event?.start ? format(event.start, FORMATTERS.DATE.DATE) : ''}</span>
      </div>
      <div className='grid grid-cols-2 gap-x-4'>
        <span className='font-medium text-gray-500'>{t('eventTime')}</span>
        <span>{`${startTime} ${LITERALS.DASH} ${endTime}`}</span>
      </div>
    </div>
  )
}

const MainCalendar = () => {
  return <Calendar<ExtendedEvent> events={[]} PopoverContentComponent={PopoverContentComponent} />
}
export default MainCalendar
