import { PropsWithChildren, useEffect, useRef, RefObject } from 'react'
import { clsx } from 'clsx'
import useEsc from '../hooks/useEsc'
import { ModalProvider, useModalContext } from './ModalProvider'
import { CloseIcon } from '../Icons/Close'
import { IconButton } from '../Buttons'
import { createPortal } from 'react-dom'
import { cva } from 'class-variance-authority'
import { MODAL_PORTALS_ELEMENT_ID } from '../constants'
import { useFormState } from 'react-hook-form'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'

export interface ModalProps extends PropsWithChildren {
  closeOnBackdrop?: boolean
  closeOnEsc?: boolean
  isOpen: boolean
  containerClassName?: string
  contentClassName?: string
  onClose: () => void
  inPortal?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg'
}

interface ModalHeaderProps extends PropsWithChildren {
  title?: string
  className?: string
  onClose?: () => void
}

interface ModalFooterProps extends PropsWithChildren {
  className?: string
}

interface ModalBodyProps extends PropsWithChildren {
  className?: string
}

interface ModalCloseOnEscProps extends PropsWithChildren {
  onClose?: () => void
}

interface ModalCloseOnOutsideClickProps extends PropsWithChildren {
  isOpen: boolean
  onClose?: () => void
  containerRef?: RefObject<HTMLDivElement>
}

export const modalVariants = cva(['max-h-full w-full'], {
  variants: {
    size: {
      xs: 'max-w-md',
      sm: 'max-w-lg',
      md: 'max-w-4xl',
      lg: 'max-w-7xl',
    },
  },
})

const Modal = ({
  children,
  containerClassName,
  contentClassName,
  isOpen,
  onClose,
  closeOnBackdrop,
  closeOnEsc = false,
  inPortal,
  size = 'sm',
}: ModalProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const ref = useRef<HTMLElement | null>(null)

  useEffect(() => {
    ref.current = document.getElementById(MODAL_PORTALS_ELEMENT_ID)
  }, [])

  if (inPortal && ref.current) {
    return createPortal(
      <div
        tabIndex={-1}
        className={clsx(
          isOpen ? 'visible' : 'hidden',
          'fixed left-0 right-0 top-0 z-100 flex size-full max-h-full items-center justify-center overflow-y-auto overflow-x-hidden bg-black/25 py-10',
        )}
      >
        <ModalProvider onClose={onClose} containerRef={containerRef}>
          <div
            ref={containerRef}
            className={clsx(
              modalVariants({ size }),
              'flex flex-col overflow-hidden rounded-lg bg-white',
              containerClassName,
            )}
          >
            {isOpen && children}
            {closeOnBackdrop && (
              <Modal.CloseOnOutsideClick onClose={onClose} isOpen={isOpen} containerRef={containerRef} />
            )}
            {closeOnEsc && <Modal.CloseOnEsc onClose={onClose} />}
          </div>
        </ModalProvider>
      </div>,
      ref.current,
    )
  }

  return (
    <div
      tabIndex={-1}
      className={clsx(
        isOpen ? 'visible' : 'hidden',
        'fixed left-0 right-0 top-0 z-100 flex size-full max-h-full items-center justify-center overflow-y-auto overflow-x-hidden bg-black/25 py-10',
      )}
    >
      <ModalProvider onClose={onClose} containerRef={containerRef}>
        <div
          ref={containerRef}
          className={clsx(
            modalVariants({ size }),
            'flex flex-col overflow-hidden rounded-lg bg-white',
            containerClassName,
          )}
        >
          <div className={clsx('relative', contentClassName)}>{children}</div>
          {closeOnBackdrop && (
            <Modal.CloseOnOutsideClick onClose={onClose} isOpen={isOpen} containerRef={containerRef} />
          )}
          {closeOnEsc && <Modal.CloseOnEsc onClose={onClose} />}
        </div>
      </ModalProvider>
    </div>
  )
}

Modal.Header = function ModalHeader({ children, title, className, onClose }: ModalHeaderProps) {
  const { onClose: handleModalClose, isFormDirty } = useModalContext()
  const hasChildren = Boolean(children)
  const { t } = useTranslation('common')

  const handleClose = () => {
    if (isFormDirty && !window.confirm(t('confirmUnsavedChangesDialogText'))) {
      return
    }

    if (onClose) {
      onClose()
    } else {
      handleModalClose()
    }
  }

  return (
    <div className={clsx('flex items-center justify-between border-b border-solid border-gray-200 p-6', className)}>
      {hasChildren ? children : <h3 className="text-base font-medium text-gray-900">{title}</h3>}
      {(!hasChildren || onClose) && (
        <IconButton size="xs" className="p-0" variant="text" theme="secondary" onClick={handleClose}>
          <CloseIcon />
          <span className="sr-only">Close modal</span>
        </IconButton>
      )}
    </div>
  )
}

Modal.Body = function ModalBody({ children, className = '' }: ModalBodyProps) {
  return <div className={clsx(className)}>{children}</div>
}

Modal.Footer = function ModalFooter({ children, className = '' }: ModalFooterProps) {
  return <div className={clsx('flex items-center rounded-b border-t border-gray-200 p-6', className)}>{children}</div>
}

Modal.FormDirtyStatus = function FormDirtyStatus() {
  const { setIsFormDirty } = useModalContext()
  const { dirtyFields } = useFormState()
  const isFormDirty = !isEmpty(dirtyFields)

  useEffect(() => {
    if (setIsFormDirty) {
      setIsFormDirty(isFormDirty)
    }
  }, [isFormDirty, setIsFormDirty])

  return null
}

Modal.CloseOnEsc = function CloseOnEsc({ onClose }: ModalCloseOnEscProps) {
  const { isFormDirty } = useModalContext()

  useEsc({ enabled: true, cb: onClose, isFormDirty })

  return null
}

Modal.CloseOnOutsideClick = function CloseOnOutsideClick({ onClose, isOpen }: ModalCloseOnOutsideClickProps) {
  const { isFormDirty, containerRef } = useModalContext()
  const { t } = useTranslation('common')

  useEffect(() => {
    if (!isOpen || !containerRef.current) return

    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        if (!isFormDirty) {
          onClose?.()
        }
        if (isFormDirty && window.confirm(t('confirmUnsavedChangesDialogText'))) {
          onClose?.()
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, onClose, containerRef, isFormDirty])

  return null
}

export { Modal }
