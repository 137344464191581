import ROUTES from '../constants'
import { lazy, Suspense } from 'react'
import ModuleSpinner from '@repo/ui/ModuleSpinner'

const StudyPlansPage = lazy(() => import('src/pages/StudyPlansPages/StudyPlansPage'))
const CreateStudyPlanPage = lazy(() => import('src/pages/StudyPlansPages/CreateStudyPlanPage'))
const CalendarPlanPage = lazy(() => import('src/pages/CalendarPlanPage'))

export default [
  {
    path: ROUTES.STUDY_PLANS.LIST,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <StudyPlansPage />
      </Suspense>
    ),
    isScrollable: false,
  },
  {
    path: ROUTES.STUDY_PLANS.CREATE,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <CreateStudyPlanPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.STUDY_PLANS.EDIT,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <CreateStudyPlanPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.STUDY_PLANS.CALENDAR_PLAN,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <CalendarPlanPage />
      </Suspense>
    ),
  },
]
