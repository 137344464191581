import { Button } from '@repo/ui/Buttons'
import { Modal, ModalProps } from '@repo/ui/Modal'
import { FC, PropsWithChildren } from 'react'

interface ConfirmModalProps extends PropsWithChildren, ModalProps {
  title: string
  text?: string
  confirmButtonText: string
  cancelButtonText: string
  isOpen: boolean
  isDisableButton?: boolean
  onClose: () => void
  onConfirm: () => void
  onCancel?: () => void
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  title,
  text = '',
  confirmButtonText,
  cancelButtonText,
  isOpen,
  isDisableButton,
  onClose,
  onConfirm,
  onCancel,
  children,
  size = 'md',
}) => {
  return (
    <Modal size={size} inPortal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title={title}></Modal.Header>
      <Modal.Body className='p-6'>{children ? children : <p className='text-gray-500'>{text}</p>}</Modal.Body>
      <Modal.Footer className='flex gap-4'>
        <Button disabled={isDisableButton} onClick={onConfirm}>
          {confirmButtonText}
        </Button>
        <Button theme='secondary' variant='outline' disabled={isDisableButton} onClick={onCancel ? onCancel : onClose}>
          {cancelButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmModal
