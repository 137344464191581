import { Button } from '@repo/ui/Buttons'
import { Modal, ModalProps } from '@repo/ui/Modal'
import { FC, PropsWithChildren } from 'react'

interface ActionsModalProps extends PropsWithChildren, ModalProps {
  title: string
  text?: string
  firstActionButtonText: string
  secondActionButtonText: string
  cancelButtonText: string
  isOpen: boolean
  isDisableButton?: boolean
  onClose: () => void
  onFirstActionButtonClick: () => void
  onSecondActionButtonClick: () => void
  onCancel?: () => void
}

const ActionsModal: FC<ActionsModalProps> = ({
  title,
  text = '',
  firstActionButtonText,
  secondActionButtonText,
  cancelButtonText,
  isOpen,
  isDisableButton,
  onClose,
  onFirstActionButtonClick,
  onSecondActionButtonClick,
  onCancel,
  children,
  size = 'md',
}) => {
  return (
    <Modal size={size} inPortal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title={title}></Modal.Header>
      <Modal.Body className='p-6'>{children ? children : <p className='text-gray-500'>{text}</p>}</Modal.Body>
      <Modal.Footer className='flex gap-4'>
        <Button disabled={isDisableButton} onClick={onFirstActionButtonClick}>
          {firstActionButtonText}
        </Button>
        <Button theme='primary' variant='outline' disabled={isDisableButton} onClick={onSecondActionButtonClick}>
          {secondActionButtonText}
        </Button>
        <Button theme='secondary' variant='outline' disabled={isDisableButton} onClick={onCancel ? onCancel : onClose}>
          {cancelButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ActionsModal
