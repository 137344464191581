import ROUTES from '../constants'
import PromoCodesPage from 'src/pages/PromoCodesPage/PromoCodePage.tsx'

export default [
  {
    path: ROUTES.PROMOCODES.LIST,
    element: <PromoCodesPage />,
    isScrollable: false,
  },
]
