import ROUTES from '../constants'
import { lazy, Suspense } from 'react'
import ModuleSpinner from '@repo/ui/ModuleSpinner'

const AgeGroupsPage = lazy(() => import('src/pages/AgeGroupsPages/AgeGroupsListPage'))
const EditAgeGroupPage = lazy(() => import('src/pages/AgeGroupsPages/EditAgeGroupPage'))

export default [
  {
    path: ROUTES.AGE_GROUPS.LIST,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <AgeGroupsPage />
      </Suspense>
    ),
    isScrollable: false,
  },
  {
    path: ROUTES.AGE_GROUPS.EDIT,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <EditAgeGroupPage />
      </Suspense>
    ),
  },
]
