"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COMPLETION_CRITERIA_DEFAULT_PROJECT_DEFENSE_REQUIRED = exports.COMPLETION_CRITERIA_DEFAULT_MIN_SCORE = exports.COMPLETION_CRITERIA_DEFAULT_TYPE = exports.COMPLETION_CRITERIA_PERCENTAGE_DEFAULT_VALUE = exports.COMPLETION_CRITERIA_PERCENTAGE_MAX_VALUE = exports.COMPLETION_CRITERIA_PERCENTAGE_MIN_VALUE = exports.COMPLETION_CRITERIA_SCORE_MAX_VALUE = exports.COMPLETION_CRITERIA_SCORE_MIN_VALUE = void 0;
const types_1 = require("./types");
exports.COMPLETION_CRITERIA_SCORE_MIN_VALUE = 0;
exports.COMPLETION_CRITERIA_SCORE_MAX_VALUE = 99.99;
exports.COMPLETION_CRITERIA_PERCENTAGE_MIN_VALUE = 0;
exports.COMPLETION_CRITERIA_PERCENTAGE_MAX_VALUE = 100;
exports.COMPLETION_CRITERIA_PERCENTAGE_DEFAULT_VALUE = 80;
exports.COMPLETION_CRITERIA_DEFAULT_TYPE = types_1.CompletionCriteria.Points;
exports.COMPLETION_CRITERIA_DEFAULT_MIN_SCORE = null;
exports.COMPLETION_CRITERIA_DEFAULT_PROJECT_DEFENSE_REQUIRED = false;
