// TODO: create separate package to share common utils
import { EMAIL, LATIN_LETTERS_NUMBERS_SYMBOLS, ValidationError, ValidationErrors } from '@repo/contracts'
import { ControlFieldError, MappedErrors } from './types'
import type { TFunction } from 'i18next'

const getErrorByRegExpPattern = (pattern: string) => {
  switch (pattern) {
    case LATIN_LETTERS_NUMBERS_SYMBOLS.source: {
      return 'letters'
    }
    case EMAIL.source: {
      return 'email'
    }
    default:
      return 'Unknown pattern'
  }
}

export const getErrorArguments = (error: ValidationError): Record<string, string> | string | null => {
  switch (error.keyword) {
    case ValidationErrors.MIN_LENGTH:
      return error.params.limit as string
    case ValidationErrors.MAX_LENGTH:
      return error.params.limit as string
    case ValidationErrors.PATTERN:
      return getErrorByRegExpPattern(error.params.pattern as string)
    case ValidationErrors.MAX_ATTEMPTS:
      return error.params as Record<string, string>
    default:
      return null
  }
}

export const errorMapper = (errors: Array<ValidationError>, t?: TFunction): MappedErrors => {
  const errorsMap: MappedErrors = {}
  errors.forEach((error) => {
    if (error.instancePath === '') {
      const maybeMissingProperty = error.params?.missingProperty

      if (!maybeMissingProperty) {
        return
      }

      errorsMap[maybeMissingProperty as string] = {
        keyword: ValidationErrors.REQUIRED,
        argument: null,
      }

      return
    }

    const path = error.instancePath.split('/')

    const field = path[path.length - 1]
    if (errorsMap[field]) {
      return
    }

    const argument = getErrorArguments(error)

    errorsMap[field] = {
      ref: error.instancePath,
      keyword: error.keyword,
      name: t?.(field) ?? field,
      argument,
    }
  })

  return errorsMap
}

export const formatError = (t: TFunction, label?: string, error?: ControlFieldError): string | undefined => {
  // TODO: somewhere something maybe fall off
  // TODO: Kirill & Andrei
  // if (!error || !error.ref) return undefined

  if (!error) return undefined

  if (!error.keyword) {
    return error.message
  }

  const interpolationFields: Record<string, string> = {}

  if (error.argument !== null) {
    if (typeof error.argument === 'object') {
      Object.entries(error.argument).forEach(([key, value]) => {
        interpolationFields[key] = value as string
      })
    }
    if (typeof error.argument === 'string' || typeof error.argument === 'number') {
      interpolationFields['argument'] = String(error.argument)
    }
  }

  if (error.params && typeof error.params === 'object') {
    Object.entries(error.params).forEach(([key, value]) => {
      interpolationFields[key] = String(value)
    })
  }

  if (label) {
    interpolationFields['name'] = label
  }

  return t(`${error.keyword}`, interpolationFields)
}

export const getOptionsFromEnum = <T extends object>(enumObj: T, t: TFunction, translationPrefix: string) => {
  return Object.keys(enumObj)
    .filter((key) => isNaN(Number(key)))
    .map((key) => {
      const value = enumObj[key as keyof T]

      return {
        value: value,
        label: t(`${translationPrefix}_${value}`),
      }
    })
}
