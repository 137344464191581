"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRolesParamsSchema = exports.DeleteUserAddressUrlParamsSchema = exports.CombinedUserAddressesCreateResponseSchema = exports.CombinedUserAddressesResponseSchema = exports.CombinedUserAddressesFormParamsSchema = exports.CombinedUserAddressesParamsSchema = exports.NewPostFormParamsSchema = exports.NewPostResponseSchema = exports.NewPostParamsSchema = exports.UserAddressFormParamsSchema = exports.UserAddressParamsSchema = exports.NewPostSchema = exports.UserAddressResponseOptionalSchema = exports.UserAddressResponseNullableSchema = exports.UserAddressResponseSchema = exports.UserContactsUpdateFormParamsSchema = exports.UserContactsUpdateParamsSchema = exports.UserContactsResponseSchema = exports.CombinedUserGeneralDetailsFormSchema = exports.UserInterestsUpdateParamsSchema = exports.UserInterestsResponseSchema = exports.UserGeneralDetailsUpdateParamsSchema = exports.UserGeneralDetailsResponseSchema = exports.UserUrlParams = exports.UserFindOrderParams = exports.UserFindFilterParamsFormSchema = exports.UserFindExcludeFilterParamsSchema = exports.UserFindFilterParamsSchema = exports.UserSchemaCreateParams = exports.UserMeResponse = exports.UserSchemaResponse = exports.InternalUserSchema = exports.UserAvatarUrlResponseSchema = exports.UserAvatarUrlPathSchema = exports.UserWithOrganizationSchema = exports.UserWithRolesSchema = exports.UserPositionsSchema = exports.UserOrganizationSchema = exports.UserRolesSchema = exports.UserEmailSchema = exports.UserPhoneNumberSchema = exports.CreateUserFormSchema = exports.CreateUserResponse = exports.CreateUserPayload = exports.UserBaseSchema = exports.UserPhoneIdentitySchema = exports.UserEmailIdentitySchema = exports.MiddleNameSchema = exports.FirstNameSchema = exports.LastNameSchema = void 0;
exports.UserRelationshipFindParamsSchema = exports.AllUsersRelationshipResponse = exports.RelationshipResponse = exports.UserContactInfo = exports.RelationshipDeletePayload = exports.RelationshipStatusPayload = exports.CreateRelationshipPayloadFormSchema = exports.CreateRelationshipPayload = exports.UserRelationship = exports.UserRelationshipType = exports.UserRelationshipStatus = exports.UserByRoleFindResponseSchema = exports.UserFindParamsSchema = exports.StudentParams = exports.StudentFindSchemaResponse = exports.StudentResponseSchema = exports.StudentFindParamsSchema = exports.StudentFindOrderSchema = exports.StudentFindFilterParamsFormSchema = exports.StudentFindFilterParamsSchema = exports.StudentTransferFormSchema = exports.StudentTransferSchema = exports.EntrantAssignSchema = exports.EntrantCreateSchemaResponse = exports.EntrantCreateParamsSchema = exports.UserAddUpdateAvatarSchema = exports.UserAttachDetachFormSchema = exports.ResolvedUsersFindResponseSchema = exports.ResolvedUsersFindResponseItemSchema = exports.UserPackagesItemSchema = exports.UserFindResponseSchema = exports.UserFindResponseItemSchema = exports.InviteUserFormSchema = exports.UserInviteStaffResponseSchema = exports.UserInviteStaffParamsSchema = exports.UserBlockUnblockParamsSchema = exports.UserChangePasswordParamsSchema = exports.UserRequestChangePasswordParamsSchema = exports.UserRemoveEmailUrlParamsSchema = exports.UserConfirmEmailParamsSchema = exports.UserAddEmailParamsSchema = exports.UserConfirmPhoneParamsSchema = exports.UserRemovePhoneNumberParamsSchema = exports.UserAddPhoneParamsSchema = exports.UserSystemRoleParamsSchema = exports.UserPositionsResponseSchema = exports.UserPositionsFormParamsSchema = exports.UserPositionsParamsSchema = exports.UserRolesResponseSchema = exports.UserRolesFormParamsSchema = void 0;
exports.UserDocumentsValidationFindResponse = exports.UserDocumentsValidationFindPayload = exports.UserDocumentsValidationFindOrderSchema = exports.UserDocumentsValidationFindFilterSchema = exports.UserDocumentsValidationElement = exports.UserDocumentFindResponseSchema = exports.UserDocumentFindParamsSchema = exports.UserDataTestAttemptFilterSchema = exports.UserBaseDataForTestAttemptFilterSchema = exports.ValidateDocumentPayload = exports.SubmitUserDocumentsToReviewPayload = exports.UpdateMetadataDocumentsPayload = exports.UpdateMetadataDocuments = exports.DeleteUserDocumentsPayload = exports.UploadDocumentPayload = exports.UserDocumentResponse = exports.GetUserDocumentParams = exports.UserDocumentPayload = exports.UserDocumentSchema = exports.UserRelationshipParams = exports.FindAllUsersRelationshipResponse = exports.FindRelationshipResponse = exports.FindRelationshipPayload = exports.UserRelationshipFindOrderParams = void 0;
const typebox_1 = require("@sinclair/typebox");
const user_validation_rules_1 = require("../../validation-rules/user.validation-rules");
const baseEntity_1 = require("../baseEntity");
const constants_1 = require("./constants");
const types_1 = require("./types");
const common_1 = require("../../common");
const utils_1 = require("../../utils");
const user_group_1 = require("../user-group");
const responses_1 = require("../../responses");
const packages_1 = require("../packages");
exports.LastNameSchema = typebox_1.Type.String({
    minLength: 1,
    maxLength: 100,
    pattern: user_validation_rules_1.LATIN_LETTERS_NUMBERS_SYMBOLS.source,
});
exports.FirstNameSchema = typebox_1.Type.String({
    minLength: 1,
    maxLength: 100,
    pattern: user_validation_rules_1.LATIN_LETTERS_NUMBERS_SYMBOLS.source,
});
exports.MiddleNameSchema = typebox_1.Type.Optional(typebox_1.Type.String({
    maxLength: 100,
    pattern: user_validation_rules_1.LATIN_LETTERS_NUMBERS_SYMBOLS.source,
}));
// TODO: move to auth
exports.UserEmailIdentitySchema = typebox_1.Type.Object({
    email: common_1.EmailSchema,
    password: common_1.PasswordSchema,
});
// TODO: move to auth
exports.UserPhoneIdentitySchema = typebox_1.Type.Object({
    phone: common_1.PhoneNumberSchema,
    password: common_1.PasswordSchema,
});
exports.UserBaseSchema = typebox_1.Type.Object({
    last_name: exports.LastNameSchema,
    first_name: exports.FirstNameSchema,
    middle_name: exports.MiddleNameSchema,
    password: common_1.PasswordSchema,
    code: typebox_1.Type.Integer(),
    is_verified: typebox_1.Type.Boolean(),
    contact_priority: typebox_1.Type.Enum(types_1.ContactPriority),
});
exports.CreateUserPayload = typebox_1.Type.Composite([
    typebox_1.Type.Pick(exports.UserBaseSchema, ['first_name', 'last_name', 'middle_name']),
    typebox_1.Type.Object({
        email: common_1.EmailSchema,
        password: common_1.PasswordSchema,
        birthdate: typebox_1.Type.Union([typebox_1.Type.Unsafe({ type: 'string', format: 'date' }), typebox_1.Type.Null()]),
        system_role: typebox_1.Type.Union([typebox_1.Type.Literal(types_1.SystemRole.Student), typebox_1.Type.Literal(types_1.SystemRole.Parent)]),
    }),
]);
exports.CreateUserResponse = typebox_1.Type.Object({ id: typebox_1.Type.Integer(), email: common_1.EmailSchema, password: common_1.PasswordSchema });
exports.CreateUserFormSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.CreateUserPayload, ['system_role', 'birthdate']),
    typebox_1.Type.Object({
        system_role: common_1.FormLookupOption,
    }),
]);
exports.UserPhoneNumberSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    phone_number: common_1.PhoneNumberSchema,
    is_main: typebox_1.Type.Boolean(),
    is_confirmed: typebox_1.Type.Boolean(),
});
exports.UserEmailSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    email: common_1.EmailSchema,
    is_main: typebox_1.Type.Boolean(),
    is_confirmed: typebox_1.Type.Boolean(),
});
exports.UserRolesSchema = typebox_1.Type.Object({
    roles: typebox_1.Type.Array(typebox_1.Type.Integer()),
}, { additionalProperties: false });
exports.UserOrganizationSchema = typebox_1.Type.Object({
    organization_id: typebox_1.Type.Integer(),
});
exports.UserPositionsSchema = typebox_1.Type.Object({
    positions: typebox_1.Type.Array(typebox_1.Type.Object({ id: typebox_1.Type.Integer(), name: typebox_1.Type.String() })),
});
exports.UserWithRolesSchema = typebox_1.Type.Composite([exports.UserBaseSchema, exports.UserRolesSchema]);
exports.UserWithOrganizationSchema = typebox_1.Type.Composite([exports.UserBaseSchema, exports.UserOrganizationSchema]);
exports.UserAvatarUrlPathSchema = typebox_1.Type.Object({
    avatar_url_path: (0, common_1.Nullable)(typebox_1.Type.String()),
});
exports.UserAvatarUrlResponseSchema = exports.UserAvatarUrlPathSchema;
exports.InternalUserSchema = typebox_1.Type.Intersect([
    baseEntity_1.BaseEntitySchema,
    exports.UserBaseSchema,
    exports.UserRolesSchema,
    exports.UserOrganizationSchema,
    typebox_1.Type.Object({
        email: typebox_1.Type.Array(common_1.EmailSchema),
        system_role: typebox_1.Type.Enum(types_1.SystemRole, { default: types_1.SystemRole.Student }),
        last_login_at: (0, common_1.Nullable)(typebox_1.Type.String({ format: 'date-time' })),
        status: typebox_1.Type.Enum(types_1.UserStatus, { default: types_1.UserStatus.Active }),
    }),
    exports.UserAvatarUrlPathSchema,
]);
exports.UserSchemaResponse = typebox_1.Type.Omit(exports.InternalUserSchema, ['password', 'is_verified', 'contact_priority']);
exports.UserMeResponse = typebox_1.Type.Composite([
    exports.UserSchemaResponse,
    typebox_1.Type.Object({
        user_groups: typebox_1.Type.Array(typebox_1.Type.Pick(user_group_1.UserGroupSchema, ['id', 'name'])),
    }),
]);
exports.UserSchemaCreateParams = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.UserWithOrganizationSchema, ['code']),
    typebox_1.Type.Object({
        phone_number: typebox_1.Type.Optional(common_1.PhoneNumberSchema),
        email: common_1.EmailSchema,
        last_login_at: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'date-time' })),
        status: typebox_1.Type.Optional(typebox_1.Type.Enum(types_1.UserStatus)),
    }),
]);
// TODO: Move to utils and name like: makeFilter or smth like that
exports.UserFindFilterParamsSchema = typebox_1.Type.Partial(typebox_1.Type.Object({
    code: typebox_1.Type.Integer(),
    first_name: exports.FirstNameSchema,
    last_name: exports.LastNameSchema,
    middle_name: typebox_1.Type.String({
        minLength: constants_1.MIN_SEARCH_LENGTH,
        maxLength: 100,
        pattern: user_validation_rules_1.LATIN_LETTERS_NUMBERS_SYMBOLS.source,
    }),
    position: typebox_1.Type.Array(typebox_1.Type.Integer()),
    system_role: typebox_1.Type.Array(typebox_1.Type.Enum(types_1.SystemRole)),
    status: typebox_1.Type.Enum(types_1.UserStatus),
    email: common_1.WeakEmailSchema,
    user_groups: typebox_1.Type.Array(typebox_1.Type.Integer()),
    packages: typebox_1.Type.Array(typebox_1.Type.Integer()),
}));
exports.UserFindExcludeFilterParamsSchema = typebox_1.Type.Partial(typebox_1.Type.Object({
    system_role: typebox_1.Type.Array(typebox_1.Type.Enum(types_1.SystemRole)),
}));
exports.UserFindFilterParamsFormSchema = typebox_1.Type.Partial(typebox_1.Type.Object({
    first_name: typebox_1.Type.String(),
    last_name: typebox_1.Type.String(),
    middle_name: typebox_1.Type.String(),
    email: typebox_1.Type.String(),
    position: typebox_1.Type.Array(common_1.FormLookupOption),
    system_role: typebox_1.Type.Array(common_1.FormLookupOption),
    status: common_1.FormLookupOption,
}));
exports.UserFindOrderParams = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.UserSchemaResponse, [
    'code',
    'first_name',
    'last_name',
    'middle_name',
    'updated_at',
    'created_at',
    'last_login_at',
    'status',
]));
// Profile schemas
exports.UserUrlParams = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
exports.UserGeneralDetailsResponseSchema = typebox_1.Type.Composite([
    typebox_1.Type.Pick(exports.UserBaseSchema, ['first_name', 'last_name', 'middle_name']),
    typebox_1.Type.Object({
        first_name_lat: typebox_1.Type.Union([typebox_1.Type.Optional(exports.FirstNameSchema), typebox_1.Type.Null()]),
        last_name_lat: typebox_1.Type.Union([typebox_1.Type.Optional(exports.LastNameSchema), typebox_1.Type.Null()]),
        birthdate: typebox_1.Type.Union([typebox_1.Type.Unsafe({ type: 'string', format: 'date' }), typebox_1.Type.Null()]),
        sex: typebox_1.Type.Union([typebox_1.Type.Enum(types_1.Sex), typebox_1.Type.Null()]),
    }),
    exports.UserAvatarUrlPathSchema,
]);
exports.UserGeneralDetailsUpdateParamsSchema = exports.UserGeneralDetailsResponseSchema;
exports.UserInterestsResponseSchema = typebox_1.Type.Object({
    about_me: typebox_1.Type.String({ maxLength: constants_1.MAX_ABOUT_ME_LENGTH }),
});
exports.UserInterestsUpdateParamsSchema = exports.UserInterestsResponseSchema;
exports.CombinedUserGeneralDetailsFormSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.UserGeneralDetailsUpdateParamsSchema, ['sex']),
    typebox_1.Type.Object({
        sex: typebox_1.Type.Optional(common_1.FormLookupOption),
    }),
    exports.UserInterestsUpdateParamsSchema,
    typebox_1.Type.Object({
        roles: typebox_1.Type.Array(common_1.FormLookupOption),
    }),
    typebox_1.Type.Object({
        positions: typebox_1.Type.Array(common_1.FormLookupOption),
    }),
]);
exports.UserContactsResponseSchema = typebox_1.Type.Object({
    phones: typebox_1.Type.Array(exports.UserPhoneNumberSchema, { minItems: 1 }),
    emails: typebox_1.Type.Array(exports.UserEmailSchema, { minItems: 1 }),
    contact_priority: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.Null(), typebox_1.Type.Enum(types_1.ContactPriority)])),
});
exports.UserContactsUpdateParamsSchema = typebox_1.Type.Object({
    contact_priority: typebox_1.Type.Enum(types_1.ContactPriority),
});
exports.UserContactsUpdateFormParamsSchema = typebox_1.Type.Object({
    contact_priority: common_1.FormLookupOption,
});
// TODO: move to addresses
exports.UserAddressResponseSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Optional(typebox_1.Type.Integer()),
    country: typebox_1.Type.Object({
        id: typebox_1.Type.Integer(),
        // TODO: Should be keys of iso country format
        iso: typebox_1.Type.String(),
    }),
    state: typebox_1.Type.String({ maxLength: 100 }),
    city: typebox_1.Type.String({ minLength: 1, maxLength: 100 }),
    district: typebox_1.Type.String({ maxLength: 100 }),
    street: typebox_1.Type.String({ minLength: 1, maxLength: 100 }),
    house: typebox_1.Type.String({ minLength: 1, maxLength: 20 }),
    flat: typebox_1.Type.Optional(typebox_1.Type.String({ maxLength: 5 })),
    index: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String({ minLength: 5, maxLength: 5, pattern: user_validation_rules_1.POST_CODE.source }), typebox_1.Type.Null()])),
    type: typebox_1.Type.Enum(types_1.AddressType),
}, { additionalProperties: false });
exports.UserAddressResponseNullableSchema = (0, utils_1.MakeNullable)(exports.UserAddressResponseSchema, [
    'district',
    'state',
    'flat',
    'index',
]);
exports.UserAddressResponseOptionalSchema = (0, utils_1.MakeOptional)(exports.UserAddressResponseSchema, [
    'district',
    'state',
    'flat',
    'index',
]);
exports.NewPostSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Optional(typebox_1.Type.Integer()),
    type: typebox_1.Type.Literal('NewPost'),
    obtaining_type: typebox_1.Type.Enum(types_1.DeliveryObtainType),
    city: typebox_1.Type.String({ minLength: 1, maxLength: 100 }),
    address: typebox_1.Type.String({ minLength: 1, maxLength: 100 }),
});
exports.UserAddressParamsSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.UserAddressResponseOptionalSchema, ['type', 'country']),
    typebox_1.Type.Object({ country_id: typebox_1.Type.Integer() }),
]);
exports.UserAddressFormParamsSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.UserAddressResponseOptionalSchema, ['type', 'country']),
    typebox_1.Type.Object({
        country: common_1.FormLookupOption,
    }),
]);
exports.NewPostParamsSchema = typebox_1.Type.Omit(exports.NewPostSchema, ['type']);
exports.NewPostResponseSchema = exports.NewPostSchema;
exports.NewPostFormParamsSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.NewPostParamsSchema, ['obtaining_type']),
    typebox_1.Type.Object({
        obtaining_type: common_1.FormLookupOption,
    }),
]);
exports.CombinedUserAddressesParamsSchema = typebox_1.Type.Partial(typebox_1.Type.Object({
    registrationAddress: typebox_1.Type.Union([exports.UserAddressParamsSchema, typebox_1.Type.Null()]),
    deliveryAddress: typebox_1.Type.Union([exports.UserAddressParamsSchema, typebox_1.Type.Null()]),
    new_post: typebox_1.Type.Union([exports.NewPostParamsSchema, typebox_1.Type.Null()]),
}));
exports.CombinedUserAddressesFormParamsSchema = typebox_1.Type.Partial(typebox_1.Type.Object({
    registrationAddress: typebox_1.Type.Union([exports.UserAddressFormParamsSchema, typebox_1.Type.Null()]),
    deliveryAddress: typebox_1.Type.Union([exports.UserAddressFormParamsSchema, typebox_1.Type.Null()]),
    new_post: typebox_1.Type.Union([exports.NewPostFormParamsSchema, typebox_1.Type.Null()]),
}));
exports.CombinedUserAddressesResponseSchema = typebox_1.Type.Object({
    registrationAddress: typebox_1.Type.Union([exports.UserAddressResponseNullableSchema, typebox_1.Type.Null()]),
    deliveryAddress: typebox_1.Type.Union([exports.UserAddressResponseNullableSchema, typebox_1.Type.Null()]),
    new_post: typebox_1.Type.Union([exports.NewPostResponseSchema, typebox_1.Type.Null()]),
});
exports.CombinedUserAddressesCreateResponseSchema = typebox_1.Type.Object({
    registrationAddress: typebox_1.Type.Union([typebox_1.Type.Number(), typebox_1.Type.Null()]),
    deliveryAddress: typebox_1.Type.Union([typebox_1.Type.Number(), typebox_1.Type.Null()]),
    new_post: typebox_1.Type.Union([typebox_1.Type.Number(), typebox_1.Type.Null()]),
});
exports.DeleteUserAddressUrlParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    addressId: typebox_1.Type.Integer(),
});
exports.UserRolesParamsSchema = typebox_1.Type.Array(typebox_1.Type.Integer());
exports.UserRolesFormParamsSchema = typebox_1.Type.Array(common_1.FormLookupOption);
exports.UserRolesResponseSchema = typebox_1.Type.Array(typebox_1.Type.Object({ id: typebox_1.Type.Integer(), name: typebox_1.Type.String() }));
exports.UserPositionsParamsSchema = typebox_1.Type.Array(typebox_1.Type.Integer());
exports.UserPositionsFormParamsSchema = typebox_1.Type.Array(common_1.FormLookupOption);
exports.UserPositionsResponseSchema = typebox_1.Type.Array(typebox_1.Type.Object({ id: typebox_1.Type.Integer(), name: typebox_1.Type.String() }));
exports.UserSystemRoleParamsSchema = typebox_1.Type.Enum(types_1.SystemRole);
exports.UserAddPhoneParamsSchema = typebox_1.Type.Object({
    phoneNumber: common_1.PhoneNumberSchema,
});
exports.UserRemovePhoneNumberParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    phoneId: typebox_1.Type.Integer(),
});
exports.UserConfirmPhoneParamsSchema = typebox_1.Type.Object({
    phoneNumber: common_1.PhoneNumberSchema,
    code: typebox_1.Type.String({
        minLength: 1,
        maxLength: 6,
    }),
});
exports.UserAddEmailParamsSchema = typebox_1.Type.Object({
    email: common_1.EmailSchema,
});
exports.UserConfirmEmailParamsSchema = typebox_1.Type.Object({
    token: typebox_1.Type.String(),
});
exports.UserRemoveEmailUrlParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    emailId: typebox_1.Type.Integer(),
});
exports.UserRequestChangePasswordParamsSchema = typebox_1.Type.Object({
    password: common_1.PasswordSchema,
});
exports.UserChangePasswordParamsSchema = typebox_1.Type.Object({
    password: common_1.PasswordSchema,
    confirmPassword: typebox_1.Type.Unsafe({ type: 'string', const: { $data: '1/password' } }),
});
exports.UserBlockUnblockParamsSchema = typebox_1.Type.Object({
    userIds: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
exports.UserInviteStaffParamsSchema = typebox_1.Type.Composite([
    typebox_1.Type.Pick(exports.UserBaseSchema, ['first_name', 'last_name', 'middle_name']),
    typebox_1.Type.Object({
        email: common_1.EmailSchema,
        system_role: typebox_1.Type.Union([
            typebox_1.Type.Literal(types_1.SystemRole.Administrator),
            typebox_1.Type.Literal(types_1.SystemRole.Teacher),
            typebox_1.Type.Literal(types_1.SystemRole.Mentor),
            typebox_1.Type.Literal(types_1.SystemRole.Marketer),
            typebox_1.Type.Literal(types_1.SystemRole.SeniorTeacher),
            typebox_1.Type.Literal(types_1.SystemRole.Parent),
        ]),
    }),
], { additionalProperties: false });
exports.UserInviteStaffResponseSchema = typebox_1.Type.Object({
    inviteUrl: typebox_1.Type.String({ format: 'uri' }),
}, { additionalProperties: false });
exports.InviteUserFormSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.UserInviteStaffParamsSchema, ['system_role']),
    typebox_1.Type.Object({
        system_role: common_1.FormLookupOption,
    }),
]);
exports.UserFindResponseItemSchema = typebox_1.Type.Composite([
    exports.UserSchemaResponse,
    exports.UserPositionsSchema,
    typebox_1.Type.Object({
        user_groups: typebox_1.Type.Array(typebox_1.Type.Pick(user_group_1.UserGroupSchema, ['id', 'name'])),
    }),
]);
exports.UserFindResponseSchema = (0, responses_1.PaginatedList)(exports.UserFindResponseItemSchema);
exports.UserPackagesItemSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: packages_1.PackageTitleSchema,
});
exports.ResolvedUsersFindResponseItemSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.UserSchemaResponse, ['roles']),
    exports.UserPositionsSchema,
    typebox_1.Type.Object({
        roles: typebox_1.Type.Array(typebox_1.Type.Object({ id: typebox_1.Type.Integer(), name: typebox_1.Type.String(), is_editable: typebox_1.Type.Boolean() })),
        user_groups: typebox_1.Type.Array(typebox_1.Type.Pick(user_group_1.UserGroupSchema, ['id', 'name'])),
        packages: typebox_1.Type.Array(exports.UserPackagesItemSchema),
    }),
]);
exports.ResolvedUsersFindResponseSchema = (0, responses_1.PaginatedList)(exports.ResolvedUsersFindResponseItemSchema);
exports.UserAttachDetachFormSchema = typebox_1.Type.Object({
    user_groups: typebox_1.Type.Array(common_1.FormLookupOption),
});
exports.UserAddUpdateAvatarSchema = typebox_1.Type.Object({
    avatar: common_1.PictureFileSchema,
});
exports.EntrantCreateParamsSchema = typebox_1.Type.Object({
    first_name: exports.FirstNameSchema,
    last_name: exports.LastNameSchema,
    middle_name: exports.MiddleNameSchema,
    email: common_1.EmailSchema,
    age_group_id: typebox_1.Type.Integer(),
});
exports.EntrantCreateSchemaResponse = typebox_1.Type.Object({
    user_id: typebox_1.Type.Integer(),
    age_group_id: typebox_1.Type.Integer(),
    classroom_id: (0, common_1.Nullable)(typebox_1.Type.Integer()),
});
exports.EntrantAssignSchema = typebox_1.Type.Object({
    classroomId: typebox_1.Type.Optional(typebox_1.Type.Integer()),
});
exports.StudentTransferSchema = typebox_1.Type.Object({
    user_ids: typebox_1.Type.Array(typebox_1.Type.Integer()),
    age_group_id: typebox_1.Type.Integer(),
    classroom_id: typebox_1.Type.Optional(typebox_1.Type.Integer()),
    reason: typebox_1.Type.String({ maxLength: 255 }),
});
exports.StudentTransferFormSchema = typebox_1.Type.Pick(exports.StudentTransferSchema, ['reason']);
exports.StudentFindFilterParamsSchema = typebox_1.Type.Object({
    first_name: typebox_1.Type.Optional(exports.FirstNameSchema),
    last_name: typebox_1.Type.Optional(exports.LastNameSchema),
    middle_name: typebox_1.Type.Optional(exports.MiddleNameSchema),
    email: typebox_1.Type.Optional(common_1.EmailSchema),
    age_group_ids: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.Integer())),
    classroom_ids: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.Array(typebox_1.Type.Integer()), typebox_1.Type.Null()])),
}, { additionalProperties: false });
exports.StudentFindFilterParamsFormSchema = typebox_1.Type.Partial(typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.StudentFindFilterParamsSchema, ['age_group_ids', 'classroom_ids']),
    typebox_1.Type.Object({
        age_group_ids: typebox_1.Type.Array(common_1.FormLookupOption),
        classroom_ids: typebox_1.Type.Array(common_1.FormLookupOption),
    }),
]));
exports.StudentFindOrderSchema = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.UserSchemaResponse, [
    'last_name',
    'created_at',
    'updated_at',
]));
exports.StudentFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.StudentFindFilterParamsSchema,
    order: exports.StudentFindOrderSchema,
});
exports.StudentResponseSchema = typebox_1.Type.Composite([
    typebox_1.Type.Pick(exports.UserSchemaResponse, ['id', 'first_name', 'last_name', 'middle_name', 'created_at', 'updated_at']),
    typebox_1.Type.Pick(exports.EntrantCreateSchemaResponse, ['age_group_id', 'classroom_id']),
    typebox_1.Type.Object({
        email: common_1.EmailSchema,
        age_group_name: typebox_1.Type.String(),
        classroom_name: typebox_1.Type.String(),
    }),
]);
exports.StudentFindSchemaResponse = (0, responses_1.PaginatedList)(exports.StudentResponseSchema);
exports.StudentParams = typebox_1.Type.Object({
    userId: typebox_1.Type.Integer(),
});
exports.UserFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.UserFindFilterParamsSchema,
    exclude: exports.UserFindExcludeFilterParamsSchema,
    order: exports.UserFindOrderParams,
});
exports.UserByRoleFindResponseSchema = (0, responses_1.PaginatedList)(typebox_1.Type.Composite([
    baseEntity_1.BaseEntitySchema,
    typebox_1.Type.Omit(exports.UserBaseSchema, ['password', 'is_verified', 'contact_priority']),
    exports.UserOrganizationSchema,
    typebox_1.Type.Object({
        email: typebox_1.Type.Array(common_1.EmailSchema),
        system_role: typebox_1.Type.Enum(types_1.SystemRole, { default: types_1.SystemRole.Student }),
        last_login_at: (0, common_1.Nullable)(typebox_1.Type.String({ format: 'date-time' })),
        status: typebox_1.Type.Enum(types_1.UserStatus, { default: types_1.UserStatus.Active }),
    }),
    exports.UserAvatarUrlPathSchema,
    exports.UserPositionsSchema,
    typebox_1.Type.Object({
        roles: typebox_1.Type.Array(typebox_1.Type.Object({ id: typebox_1.Type.Integer(), name: typebox_1.Type.String(), is_editable: typebox_1.Type.Boolean() })),
    }),
]));
var UserRelationshipStatus;
(function (UserRelationshipStatus) {
    UserRelationshipStatus["Pending"] = "Pending";
    UserRelationshipStatus["Accepted"] = "Accepted";
    UserRelationshipStatus["Rejected"] = "Rejected";
})(UserRelationshipStatus || (exports.UserRelationshipStatus = UserRelationshipStatus = {}));
var UserRelationshipType;
(function (UserRelationshipType) {
    UserRelationshipType["Parent"] = "Parent";
    UserRelationshipType["Child"] = "Child";
})(UserRelationshipType || (exports.UserRelationshipType = UserRelationshipType = {}));
var UserRelationship;
(function (UserRelationship) {
    UserRelationship["Mother"] = "Mother";
    UserRelationship["Father"] = "Father";
    UserRelationship["Child"] = "Child";
})(UserRelationship || (exports.UserRelationship = UserRelationship = {}));
exports.CreateRelationshipPayload = typebox_1.Type.Object({
    relationship: typebox_1.Type.Enum(UserRelationship),
    initiator: typebox_1.Type.Enum(UserRelationshipType),
    email: common_1.EmailSchema,
});
exports.CreateRelationshipPayloadFormSchema = typebox_1.Type.Object({
    relationship: common_1.FormLookupOption,
    initiator: common_1.FormLookupOption,
    email: common_1.EmailSchema,
});
exports.RelationshipStatusPayload = typebox_1.Type.Object({
    status: typebox_1.Type.Enum(UserRelationshipStatus),
});
exports.RelationshipDeletePayload = typebox_1.Type.Object({
    relationshipIds: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
exports.UserContactInfo = typebox_1.Type.Object({
    user_id: typebox_1.Type.Integer(),
    first_name: exports.FirstNameSchema,
    middle_name: exports.MiddleNameSchema,
    last_name: exports.LastNameSchema,
    email: common_1.EmailSchema,
    phone_number: (0, common_1.Nullable)(common_1.PhoneNumberSchema),
});
exports.RelationshipResponse = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    parent: exports.UserContactInfo,
    child: exports.UserContactInfo,
    type: typebox_1.Type.Enum(UserRelationshipType),
    status: typebox_1.Type.Enum(UserRelationshipStatus),
    relationship: typebox_1.Type.Enum(UserRelationship),
});
exports.AllUsersRelationshipResponse = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    first_name: typebox_1.Type.String(),
    last_name: typebox_1.Type.String(),
    middle_name: typebox_1.Type.String(),
    email: common_1.EmailSchema,
    phone_number: (0, common_1.Nullable)(common_1.PhoneNumberSchema),
    created_at: typebox_1.Type.String({ format: 'date-time' }),
    parents: typebox_1.Type.Array(typebox_1.Type.Object({
        id: typebox_1.Type.Integer(),
        relationship_id: typebox_1.Type.Integer(),
        first_name: typebox_1.Type.String(),
        last_name: typebox_1.Type.String(),
        middle_name: typebox_1.Type.String(),
        type: typebox_1.Type.Enum(UserRelationshipType),
        status: typebox_1.Type.Enum(UserRelationshipStatus),
        relationship: typebox_1.Type.Enum(UserRelationship),
    })),
});
exports.UserRelationshipFindParamsSchema = typebox_1.Type.Partial(typebox_1.Type.Composite([
    typebox_1.Type.Pick(exports.RelationshipResponse, ['type', 'status', 'relationship']),
    typebox_1.Type.Pick(exports.UserContactInfo, ['first_name', 'last_name', 'middle_name']),
    typebox_1.Type.Object({
        email: common_1.WeakEmailSchema,
    }),
]));
exports.UserRelationshipFindOrderParams = typebox_1.Type.Partial(typebox_1.Type.Object({
    last_name: exports.LastNameSchema,
    status: typebox_1.Type.Enum(UserRelationshipStatus),
    type: typebox_1.Type.Enum(UserRelationshipType),
}));
exports.FindRelationshipPayload = (0, responses_1.FindParams)({
    filters: exports.UserRelationshipFindParamsSchema,
    order: exports.UserRelationshipFindOrderParams,
});
exports.FindRelationshipResponse = (0, responses_1.PaginatedList)(exports.RelationshipResponse);
exports.FindAllUsersRelationshipResponse = (0, responses_1.PaginatedList)(exports.AllUsersRelationshipResponse);
exports.UserRelationshipParams = typebox_1.Type.Object({ id: typebox_1.Type.Integer(), relationshipId: typebox_1.Type.Integer() });
exports.UserDocumentSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    user_id: typebox_1.Type.Integer(),
    document_type: typebox_1.Type.Enum(types_1.UserDocumentType),
    status: typebox_1.Type.Enum(types_1.UserDocumentStatus),
    package_id: (0, common_1.Nullable)(typebox_1.Type.String({ format: 'uuid' })),
    document_series: (0, common_1.Nullable)(typebox_1.Type.String()),
    document_number: (0, common_1.Nullable)(typebox_1.Type.String()),
    file_path: (0, common_1.Nullable)(typebox_1.Type.String()),
    comment: (0, common_1.Nullable)(typebox_1.Type.String()),
    created_at: typebox_1.Type.String({ format: 'date-time' }),
    updated_at: typebox_1.Type.String({ format: 'date-time' }),
});
exports.UserDocumentPayload = typebox_1.Type.Object({
    documents: typebox_1.Type.Array(typebox_1.Type.Object({
        document_type: typebox_1.Type.Enum(types_1.UserDocumentType),
        package_id: (0, common_1.Nullable)(typebox_1.Type.String({ format: 'uuid' })),
    })),
});
exports.GetUserDocumentParams = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    document_id: typebox_1.Type.Integer(),
});
exports.UserDocumentResponse = exports.UserDocumentSchema;
exports.UploadDocumentPayload = typebox_1.Type.Object({
    type: typebox_1.Type.Enum(types_1.UserDocumentType),
    package_id: (0, common_1.Nullable)(typebox_1.Type.String({ format: 'uuid' })),
    file: common_1.FileSchema,
});
exports.DeleteUserDocumentsPayload = typebox_1.Type.Object({
    ids: typebox_1.Type.Array(typebox_1.Type.Integer({ minimum: 1 })),
});
exports.UpdateMetadataDocuments = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    document_series: typebox_1.Type.Optional(typebox_1.Type.String()),
    document_number: typebox_1.Type.Optional(typebox_1.Type.String()),
    file_path: typebox_1.Type.Optional(typebox_1.Type.String()),
});
exports.UpdateMetadataDocumentsPayload = typebox_1.Type.Array(exports.UpdateMetadataDocuments);
exports.SubmitUserDocumentsToReviewPayload = typebox_1.Type.Object({
    ids: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
exports.ValidateDocumentPayload = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    status: typebox_1.Type.Union([typebox_1.Type.Literal(types_1.UserDocumentStatus.Approved), typebox_1.Type.Literal(types_1.UserDocumentStatus.Rejected)]),
    comment: typebox_1.Type.Optional(typebox_1.Type.String()),
});
exports.UserBaseDataForTestAttemptFilterSchema = typebox_1.Type.Pick(exports.UserBaseSchema, [
    'first_name',
    'middle_name',
    'last_name',
    'code',
]);
exports.UserDataTestAttemptFilterSchema = typebox_1.Type.Composite([
    exports.UserBaseDataForTestAttemptFilterSchema,
    typebox_1.Type.Object({
        email: common_1.EmailSchema,
    }),
]);
exports.UserDocumentFindParamsSchema = (0, responses_1.FindParams)({});
exports.UserDocumentFindResponseSchema = (0, responses_1.PaginatedList)(exports.UserDocumentSchema);
exports.UserDocumentsValidationElement = typebox_1.Type.Object({
    first_name: exports.FirstNameSchema,
    last_name: exports.LastNameSchema,
    middle_name: exports.MiddleNameSchema,
    status: typebox_1.Type.Enum(types_1.UserDocumentStatus),
    submitted_at: typebox_1.Type.String({ format: 'date-time' }),
});
exports.UserDocumentsValidationFindFilterSchema = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.UserDocumentsValidationElement, ['first_name', 'last_name', 'middle_name', 'status', 'submitted_at']));
exports.UserDocumentsValidationFindOrderSchema = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.UserDocumentsValidationElement, ['first_name', 'last_name', 'middle_name', 'submitted_at']));
exports.UserDocumentsValidationFindPayload = (0, responses_1.FindParams)({
    filters: exports.UserDocumentsValidationFindFilterSchema,
    order: exports.UserDocumentsValidationFindOrderSchema,
});
exports.UserDocumentsValidationFindResponse = (0, responses_1.PaginatedList)(exports.UserDocumentsValidationElement);
