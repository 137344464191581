import { ComponentType, MutableRefObject, useState } from 'react'
import Popper from '../../Popper'
import { IconButton } from '../../Buttons'
import { CloseIcon, PenIcon, RecycleBinIcon } from '../../Icons'
import ConfirmModal from 'back-office/src/components/modals/ConfirmModal'
import { LITERALS } from '../../constants'
import { useDisclosure } from '../../hooks/useDisclosure'
import { useTranslation } from 'react-i18next'
import { CalendarPlanDeleteType, CalendarPlanEventsDeletePayloadType } from '@repo/contracts'
import { ExtendedEvent } from '../index'
import ActionsModal from 'back-office/src/modules/StudyPlanModule/CalendarPlan/modals/ActionsModal'

interface EventDetailsPopoverProps<T> {
  sourceRef: MutableRefObject<HTMLElement | SVGSVGElement | null>
  isPopperOpen: boolean
  closePopper: () => void
  selectedEvent: T | null
  PopoverContentComponent: ComponentType<{
    event: T | null
    onClose: () => void
  }>
  deleteCalendarPlanEventByEventId?: (data: CalendarPlanEventsDeletePayloadType) => Promise<unknown>
  deleteCalendarPlanEventPending?: boolean
}

const EventDetailsPopover = <T extends ExtendedEvent>({
  sourceRef,
  isPopperOpen,
  closePopper,
  selectedEvent,
  PopoverContentComponent,
  deleteCalendarPlanEventByEventId,
  deleteCalendarPlanEventPending,
}: EventDetailsPopoverProps<T>) => {
  const { t } = useTranslation('study-plan-create')

  const [event, setEvent] = useState<T | null>(null)
  const [eventType, setEventType] = useState<CalendarPlanDeleteType | null>(null)

  const {
    isOpen: isOpenRepeatEventDeleteModal,
    onOpen: onOpenRepeatEventDeleteModal,
    onClose: onCloseRepeatEventDeleteModal,
  } = useDisclosure()

  const {
    isOpen: isOpenConfirmDeleteModal,
    onOpen: onOpenConfirmDeleteModal,
    onClose: onCloseConfirmDeleteModal,
  } = useDisclosure()

  const onConfirmDelete = async () => {
    if (event) {
      if (!eventType || eventType === CalendarPlanDeleteType.EventId) {
        await deleteCalendarPlanEventByEventId?.({ id: event.id as number, type: CalendarPlanDeleteType.EventId })
        onCloseConfirmDeleteModal()
      } else if (eventType === CalendarPlanDeleteType.EventChainId) {
        await deleteCalendarPlanEventByEventId?.({
          id: event.eventChainId,
          type: CalendarPlanDeleteType.EventChainId,
        })
        onCloseConfirmDeleteModal()
      }
    }
  }

  const handleOpenDeleteModal = () => {
    setEvent(selectedEvent)
    selectedEvent?.rrule ? onOpenRepeatEventDeleteModal() : onOpenConfirmDeleteModal()
  }

  const handleCloseConfirmDeleteModal = () => {
    setEvent(null)
    setEventType(null)
    onCloseConfirmDeleteModal()
  }

  const handleRepeatEventModalOneEventDelete = () => {
    setEventType(CalendarPlanDeleteType.EventId)
    onOpenConfirmDeleteModal()
    onCloseRepeatEventDeleteModal()
  }

  const handleRepeatEventModalEventChainDelete = () => {
    setEventType(CalendarPlanDeleteType.EventChainId)
    onOpenConfirmDeleteModal()
    onCloseRepeatEventDeleteModal()
  }

  const handleCloseRepeatEventDeleteModal = () => {
    setEvent(null)
    setEventType(null)
    onCloseRepeatEventDeleteModal()
  }

  return (
    <>
      <Popper sourceRef={sourceRef} isOpen={isPopperOpen} onClose={closePopper} placement="bottom" inPortal={true}>
        {selectedEvent && (
          <div className="w-[400px] rounded-lg border border-gray-200 bg-white p-4 shadow-lg">
            <div className="mb-4 flex items-center justify-between">
              <h3 className="text-lg font-semibold">{selectedEvent.title}</h3>
              <div className="flex space-x-4">
                <IconButton onClick={() => alert('Not implemented!')} variant="text" size="thin">
                  <PenIcon className="size-4 stroke-gray-900" />
                </IconButton>
                <IconButton onClick={handleOpenDeleteModal} variant="text" size="thin">
                  <RecycleBinIcon className="size-4 stroke-red-800" />
                </IconButton>
                <IconButton onClick={closePopper} variant="text" size="thin">
                  <CloseIcon width={16} height={16} />
                </IconButton>
              </div>
            </div>
            <hr className="mb-4 border-gray-200" />
            <PopoverContentComponent event={selectedEvent} onClose={closePopper} />
          </div>
        )}
      </Popper>
      <ActionsModal
        title={t('repeatEventDeleteModalTitle')}
        firstActionButtonText={t('repeatEventFirstActionModalButton')}
        secondActionButtonText={t('repeatEventSecondActionModalButton')}
        cancelButtonText={t('cancelButtonText')}
        isOpen={isOpenRepeatEventDeleteModal}
        isDisableButton={false}
        onClose={handleCloseRepeatEventDeleteModal}
        onFirstActionButtonClick={handleRepeatEventModalOneEventDelete}
        onSecondActionButtonClick={handleRepeatEventModalEventChainDelete}
      >
        <p className="text-gray-500">
          {t('repeatEventDeleteModalText')}
          {LITERALS.QUESTION_MARK}
        </p>
      </ActionsModal>
      <ConfirmModal
        title={t('singleEventConfirmDeleteModalTitle')}
        confirmButtonText={t('singleEventConfirmDeleteModalConfirmButton')}
        cancelButtonText={t('cancelButtonText')}
        isOpen={isOpenConfirmDeleteModal}
        isDisableButton={deleteCalendarPlanEventPending}
        onClose={handleCloseConfirmDeleteModal}
        onConfirm={onConfirmDelete}
      >
        <p className="text-gray-500">
          {t('singleEventConfirmDeleteModalText')}
          {LITERALS.QUESTION_MARK}
        </p>
      </ConfirmModal>
    </>
  )
}

export default EventDetailsPopover
