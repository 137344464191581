import ROUTES from '../constants'
import { lazy, Suspense } from 'react'
import ModuleSpinner from '@repo/ui/ModuleSpinner'

const BankQuestionsListPage = lazy(() => import('src/pages/TestsPages/BankQuestionsListPage'))
const CreateUpdateQuestionPage = lazy(() => import('src/pages/TestsPages/CreateUpdateQuestionPage'))
const TestsListPage = lazy(() => import('src/pages/TestsPages/TestsListPage'))
const MasterTestsPage = lazy(() => import('src/pages/TestsPages/MasterTestsPage'))

export default [
  {
    path: ROUTES.BANK_QUESTION.LIST,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <BankQuestionsListPage />
      </Suspense>
    ),
    isScrollable: false,
  },
  {
    path: ROUTES.BANK_QUESTION.CREATE,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <CreateUpdateQuestionPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.BANK_QUESTION.EDIT,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <CreateUpdateQuestionPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.TESTS.LIST,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <TestsListPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.TESTS.CREATE,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <MasterTestsPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.TESTS.EDIT,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <MasterTestsPage />
      </Suspense>
    ),
  },
]
