import { CALENDAR_FORMATS } from '../constants'
import { format } from 'date-fns'
import { Event as BaseEvent } from 'react-big-calendar'
import { LITERALS } from '../../constants'

// TODO: Think about how we will work with expanding event interfaces
interface EventWithType extends BaseEvent {}

interface WeekAndDayEventProps<T> {
  event: T
}

const WeekAndDayEvent = <T extends EventWithType>({ event }: WeekAndDayEventProps<T>) => {
  const startTime = event.start ? format(event.start, CALENDAR_FORMATS.TIME) : null
  const endTime = event.end ? format(event.end, CALENDAR_FORMATS.TIME) : null
  return (
    <div className="flex flex-col gap-2 whitespace-nowrap">
      <span>{event.title}</span>
      <span className="text-gray-400">{`${startTime} ${LITERALS.DASH} ${endTime}`}</span>
    </div>
  )
}

export default WeekAndDayEvent
