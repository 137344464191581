import { ErrorBoundary } from '@repo/ui/ErrorBoundary'
import ModuleSpinner from '@repo/ui/ModuleSpinner'
import { FC, Suspense, lazy } from 'react'
import LocalizationGuard from 'src/shared/guards/LocalizationGuard'
import RouterPermissionsGuard from 'src/shared/guards/RouterPermissionsGuard'

const RelationshipsList = lazy(() => import('src/modules/RelationshipsModule/RelationshipsList'))

const RelationshipsPage: FC = () => {
  return (
    <ErrorBoundary>
      <RouterPermissionsGuard permissions={{ StaffPositionManagement: ['read', 'edit'] }}>
        <LocalizationGuard namespace='relationship'>
          <Suspense fallback={<ModuleSpinner />}>
            <RelationshipsList />
          </Suspense>
        </LocalizationGuard>
      </RouterPermissionsGuard>
    </ErrorBoundary>
  )
}

export default RelationshipsPage
