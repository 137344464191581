import ROUTES from '../constants'
import RelationshipsPage from 'src/pages/RelationshipsPage/RelationshipsPage.tsx'

export default [
  {
    path: ROUTES.RELATIONSHIPS.LIST,
    element: <RelationshipsPage />,
    isScrollable: false,
  },
]
