import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
  RefObject,
  Dispatch,
  SetStateAction,
} from 'react'

interface ModalProviderContextValue {
  onClose: () => void
  isFormDirty?: boolean
  setIsFormDirty?: Dispatch<SetStateAction<boolean>>
  containerRef: RefObject<HTMLDivElement>
}

export const ModalProviderContext = createContext<ModalProviderContextValue>({} as ModalProviderContextValue)

interface ModalProviderProps extends PropsWithChildren, ModalProviderContextValue {}

export const ModalProvider = ({ onClose, containerRef, children }: ModalProviderProps) => {
  const [isFormDirty, setIsFormDirty] = useState(false)

  const providerValue = useMemo(
    () => ({
      onClose,
      isFormDirty,
      setIsFormDirty,
      containerRef,
    }),
    [onClose, isFormDirty, containerRef],
  )

  return <ModalProviderContext.Provider value={providerValue}>{children}</ModalProviderContext.Provider>
}

export const useModalContext = () => {
  return useContext(ModalProviderContext)
}
