"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TestCreateUpdateFormSchema = exports.TestsFindFilterFormSchema = exports.TestAttemptsFindListResponseSchema = exports.TestAttemptsFindResponseSchema = exports.TestAttemptBaseResponseSchema = exports.TestNameSchema = exports.TestAttemptsFindParamsSchema = exports.TestAttemptsFindOrderParams = exports.TestAttemptsFilterParamsSchema = exports.TestAttemptBaseParamsSchema = exports.TestAttemptQuestionManualCheckPayload = exports.TestAttemptQuestionManualCheckParams = exports.TestAttemptResponseSchema = exports.TestAttemptCreateSchema = exports.TestAttemptPayload = exports.TestAttemptIdContinueFinishSchema = exports.TestAttemptSchema = exports.TestAttemptBaseSchema = exports.TestAttemptIdParamsSchema = exports.TestAttemptParamsSchema = exports.TestAttachDetachQuestionsPayload = exports.TestQuestionsCreateSchema = exports.TestQuestionsBaseSchema = exports.TestAttachDetachToTopicPayload = exports.TestTopicBaseSchema = exports.TestFindParamsSchema = exports.TestFindOrderParams = exports.TestFindFilterParams = exports.TestsFindListResponseSchema = exports.TestWithQuestionSchema = exports.TestFindResponseSchema = exports.isInUsageSchema = exports.TestDeletePayload = exports.TestParamsSchema = exports.TestCreateUpdateResponseSchema = exports.TestCreateUpdatePayload = exports.TestEntitySchema = exports.TestBaseSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const types_1 = require("./types");
const constants_1 = require("./constants");
const baseEntity_1 = require("../baseEntity");
const responses_1 = require("../../responses");
const common_1 = require("../../common");
const question_1 = require("../question");
const user_1 = require("../user");
const wysiwyg_1 = require("../../types/wysiwyg");
const TestTypeSchema = typebox_1.Type.Enum(types_1.TestType);
exports.TestBaseSchema = typebox_1.Type.Object({
    name: typebox_1.Type.String({ minLength: 1, maxLength: 255 }),
    description: typebox_1.Type.String(),
    type: TestTypeSchema,
    time_limit: typebox_1.Type.Integer({
        minimum: constants_1.MIN_TEST_DURATION,
        maximum: constants_1.MAX_TEST_DURATION,
    }),
    attempts_limit: typebox_1.Type.Integer({
        minimum: constants_1.MIN_ATTEMPTS_LIMIT,
    }),
    randomize_questions: typebox_1.Type.Boolean({ default: false }),
});
exports.TestEntitySchema = typebox_1.Type.Composite([exports.TestBaseSchema, baseEntity_1.BaseEntitySchema]);
exports.TestCreateUpdatePayload = exports.TestBaseSchema;
exports.TestCreateUpdateResponseSchema = typebox_1.Type.Pick(baseEntity_1.BaseEntitySchema, ['id']);
exports.TestParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
exports.TestDeletePayload = typebox_1.Type.Object({
    ids: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
exports.isInUsageSchema = typebox_1.Type.Object({
    is_in_usage: typebox_1.Type.Boolean(),
});
exports.TestFindResponseSchema = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.TestBaseSchema, exports.isInUsageSchema]);
exports.TestWithQuestionSchema = typebox_1.Type.Composite([
    exports.TestEntitySchema,
    typebox_1.Type.Object({
        questions: typebox_1.Type.Array(question_1.QuestionSchema),
    }),
]);
exports.TestsFindListResponseSchema = (0, responses_1.PaginatedList)(exports.TestFindResponseSchema);
exports.TestFindFilterParams = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.TestBaseSchema, ['name', 'type']));
exports.TestFindOrderParams = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.TestFindResponseSchema, ['name', 'type', 'created_at', 'updated_at']));
exports.TestFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.TestFindFilterParams,
    order: exports.TestFindOrderParams,
});
exports.TestTopicBaseSchema = typebox_1.Type.Object({
    topic_id: typebox_1.Type.Integer(),
    test_id: typebox_1.Type.Integer(),
});
exports.TestAttachDetachToTopicPayload = typebox_1.Type.Object({
    topic_id: typebox_1.Type.Integer(),
    tests_ids: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
exports.TestQuestionsBaseSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    test_id: typebox_1.Type.Integer(),
    question_id: typebox_1.Type.Integer(),
});
exports.TestQuestionsCreateSchema = typebox_1.Type.Omit(exports.TestQuestionsBaseSchema, ['id']);
exports.TestAttachDetachQuestionsPayload = typebox_1.Type.Object({
    questions: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
exports.TestAttemptParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    attempt_id: typebox_1.Type.Integer(),
});
exports.TestAttemptIdParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
const ScoreSchema = typebox_1.Type.Number({
    minimum: 0,
    maximum: 999.99,
    multipleOf: 0.01,
});
exports.TestAttemptBaseSchema = typebox_1.Type.Object({
    test_id: typebox_1.Type.Integer(),
    user_id: typebox_1.Type.Integer(),
    status: typebox_1.Type.Enum(types_1.TestAttemptStatus),
    score: (0, common_1.Nullable)(ScoreSchema),
    start_time: typebox_1.Type.String({
        format: 'date-time',
    }),
    end_time: (0, common_1.Nullable)(typebox_1.Type.String({
        format: 'date-time',
    })),
});
exports.TestAttemptSchema = typebox_1.Type.Composite([exports.TestAttemptBaseSchema, baseEntity_1.BaseEntitySchema]);
exports.TestAttemptIdContinueFinishSchema = typebox_1.Type.Object({
    attempt_id: typebox_1.Type.Integer(),
});
exports.TestAttemptPayload = typebox_1.Type.Pick(exports.TestAttemptBaseSchema, ['user_id']);
exports.TestAttemptCreateSchema = typebox_1.Type.Pick(exports.TestAttemptBaseSchema, ['user_id', 'test_id', 'status']);
exports.TestAttemptResponseSchema = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.TestAttemptBaseSchema]);
exports.TestAttemptQuestionManualCheckParams = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    attempt_id: typebox_1.Type.Integer(),
    question_id: typebox_1.Type.Integer(),
});
exports.TestAttemptQuestionManualCheckPayload = typebox_1.Type.Object({
    answer_type: typebox_1.Type.Enum(types_1.AnswerType),
    points: question_1.QuestionUnsignedPointsSchema,
});
exports.TestAttemptBaseParamsSchema = typebox_1.Type.Pick(exports.TestAttemptBaseSchema, ['test_id', 'status']);
exports.TestAttemptsFilterParamsSchema = typebox_1.Type.Partial(typebox_1.Type.Composite([exports.TestAttemptBaseParamsSchema, user_1.UserDataTestAttemptFilterSchema]));
exports.TestAttemptsFindOrderParams = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.TestAttemptResponseSchema, ['status', 'created_at', 'updated_at']));
exports.TestAttemptsFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.TestAttemptsFilterParamsSchema,
    order: exports.TestAttemptsFindOrderParams,
});
exports.TestNameSchema = typebox_1.Type.Object({
    test_name: typebox_1.Type.String({ minLength: 1, maxLength: 255 }),
});
exports.TestAttemptBaseResponseSchema = typebox_1.Type.Pick(exports.TestAttemptResponseSchema, [
    'test_id',
    'score',
    'start_time',
    'end_time',
    'status',
]);
exports.TestAttemptsFindResponseSchema = typebox_1.Type.Composite([
    exports.TestAttemptBaseResponseSchema,
    exports.TestNameSchema,
    user_1.UserDataTestAttemptFilterSchema,
]);
exports.TestAttemptsFindListResponseSchema = (0, responses_1.PaginatedList)(exports.TestAttemptsFindResponseSchema);
exports.TestsFindFilterFormSchema = typebox_1.Type.Partial(typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.TestFindFilterParams, ['type']),
    typebox_1.Type.Object({
        type: common_1.FormLookupOption,
    }),
]));
exports.TestCreateUpdateFormSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.TestCreateUpdatePayload, ['description', 'type', 'time_limit', 'randomize_questions', 'questions']),
    typebox_1.Type.Object({
        description: (0, wysiwyg_1.Wysiwyg)({ minLength: 1 }),
        type: common_1.FormLookupOption,
        time_limit: common_1.FormLookupOption,
    }),
]);
