"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOnePromocodeFieldsShema = exports.PromocodeGetResponseSchema = exports.PromocodeConsultationGetResponseSchema = exports.PromocodePackageGetResponseSchema = exports.PromocodeCourseGetResponseSchema = exports.PromocodeConsultationItemsGetResponseSchema = exports.PromocodePackageItemsGetResponseSchema = exports.PromocodeCourseItemsGetResponseSchema = exports.PromocodeCoursePackageItemSchema = exports.PromocodeGetParams = exports.PromocodeFindParamsSchema = exports.PromocodeFindOrderParamsSchema = exports.PromocodeFindFilterParamsSchema = exports.PromocodeOrderParamsScema = exports.PromocodeFilterParamsSchema = exports.PromocodeFindListResponseSchema = exports.PromocodeFindResponseSchema = exports.PromocodeTypeSchema = exports.PromocodeBaseResponceSchema = exports.PromocodeCreateUpdateResponse = exports.PromocodeUpdateParamsSchema = exports.PromocodeDeleteParamsSchema = exports.PromocodeCreateUpdatePayload = exports.PromocodeConsultationCreateUpdateSchema = exports.PromocodePackageCreateUpdateSchema = exports.PromocodeCourseCreateUpdateSchema = exports.PromocodeConsultationSchema = exports.PromocodePackageSchema = exports.PromocodeCourseCreateUpdateFormSchema = exports.PromocodeCourseFormSchema = exports.PromocodeCourceSchema = exports.PromocodeCreateUpdateBaseFormSchema = exports.PromocodeCreateUpdateBaseSchema = exports.PromocodeBaseSchema = exports.PromocodeDiscountSchema = exports.PromocodeStrictDiscountSchema = exports.PromocodePercentageDiscountSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const baseEntity_1 = require("../baseEntity");
const responses_1 = require("../../responses");
const utils_1 = require("../../utils");
const promocodes_validation_rules_1 = require("../../validation-rules/promocodes.validation-rules");
const common_1 = require("../../common");
const types_1 = require("./types");
const PromocodeNameSchema = typebox_1.Type.String({
    minLength: 1,
    maxLength: 50,
    pattern: promocodes_validation_rules_1.LETTERS_NUMBERS.source,
    errorMessage: 'Name must be shorter than 50 characters and contain only letters and numbers.',
});
const PromocodeStartEndDateTimeSchema = typebox_1.Type.String({
    format: 'date-time',
});
const PromocodeStatusEnum = typebox_1.Type.Enum(types_1.PromocodeStatus);
const PromocodeUsageTypeEnum = typebox_1.Type.Enum(types_1.PromocodeUsageType);
const PromocodeConsultationTypeEnum = typebox_1.Type.Enum(types_1.PromocodeConsultationType);
const PromocodeTypeEnum = typebox_1.Type.Enum(types_1.PromocodeType);
exports.PromocodePercentageDiscountSchema = typebox_1.Type.Object({
    type: typebox_1.Type.Literal(types_1.PromocodeDiscountType.Percentage),
    value: typebox_1.Type.Integer({
        minimum: types_1.PromocodeDiscountPercentageValue.Min,
        maximum: types_1.PromocodeDiscountPercentageValue.Max,
    }),
}, { additionalProperties: false });
exports.PromocodeStrictDiscountSchema = typebox_1.Type.Object({
    type: typebox_1.Type.Literal(types_1.PromocodeDiscountType.Strict),
    value: typebox_1.Type.Integer({
        minimum: types_1.PromocodeStrictDiscountValue.Min,
        maximum: types_1.PromocodeStrictDiscountValue.Max,
    }),
}, { additionalProperties: false });
exports.PromocodeDiscountSchema = (0, utils_1.OneOf)([exports.PromocodePercentageDiscountSchema, exports.PromocodeStrictDiscountSchema], {
    type: 'object',
    discriminator: { propertyName: 'type' },
    required: ['type'],
});
exports.PromocodeBaseSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: PromocodeNameSchema,
    usage_count: (0, common_1.Nullable)(typebox_1.Type.Integer({
        minimum: types_1.PromocodeUsageCountValue.Min,
        maximum: types_1.PromocodeUsageCountValue.Max,
    })),
    discount: exports.PromocodeDiscountSchema,
    can_be_combined: typebox_1.Type.Boolean(),
    start_datetime: (0, common_1.Nullable)(PromocodeStartEndDateTimeSchema),
    end_datetime: (0, common_1.Nullable)(PromocodeStartEndDateTimeSchema),
    unlimited_date: typebox_1.Type.Boolean(),
    status: PromocodeStatusEnum,
    promo_type: PromocodeUsageTypeEnum,
    type: PromocodeTypeEnum,
    consultation_type: (0, common_1.Nullable)(PromocodeConsultationTypeEnum),
});
exports.PromocodeCreateUpdateBaseSchema = typebox_1.Type.Omit(exports.PromocodeBaseSchema, ['id', 'type', 'consultation_type']);
exports.PromocodeCreateUpdateBaseFormSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.PromocodeCreateUpdateBaseSchema, ['promo_type', 'discount']),
    typebox_1.Type.Object({
        promo_type: common_1.FormLookupOption,
        discount: common_1.FormLookupOption,
    }),
]);
exports.PromocodeCourceSchema = typebox_1.Type.Object({
    type: typebox_1.Type.Literal(types_1.PromocodeType.Course),
    ids: typebox_1.Type.Array(typebox_1.Type.Integer(), { minItems: 1 }),
}, { additionalProperties: false });
exports.PromocodeCourseFormSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.PromocodeCourceSchema, ['type', 'ids']),
    typebox_1.Type.Object({
        type: common_1.FormLookupOption,
        ids: typebox_1.Type.Array(common_1.FormLookupOption),
    }),
]);
exports.PromocodeCourseCreateUpdateFormSchema = typebox_1.Type.Composite([
    exports.PromocodeCreateUpdateBaseFormSchema,
    exports.PromocodeCourseFormSchema,
]);
exports.PromocodePackageSchema = typebox_1.Type.Object({
    type: typebox_1.Type.Literal(types_1.PromocodeType.Package),
    ids: typebox_1.Type.Array(typebox_1.Type.Integer(), { minItems: 1 }),
}, { additionalProperties: false });
exports.PromocodeConsultationSchema = typebox_1.Type.Object({
    type: typebox_1.Type.Literal(types_1.PromocodeType.Consultation),
    consultation_type: PromocodeConsultationTypeEnum,
}, { additionalProperties: false });
exports.PromocodeCourseCreateUpdateSchema = typebox_1.Type.Composite([
    exports.PromocodeCreateUpdateBaseSchema,
    exports.PromocodeCourceSchema,
]);
exports.PromocodePackageCreateUpdateSchema = typebox_1.Type.Composite([
    exports.PromocodeCreateUpdateBaseSchema,
    exports.PromocodePackageSchema,
]);
exports.PromocodeConsultationCreateUpdateSchema = typebox_1.Type.Composite([
    exports.PromocodeCreateUpdateBaseSchema,
    exports.PromocodeConsultationSchema,
]);
exports.PromocodeCreateUpdatePayload = (0, utils_1.OneOf)([exports.PromocodeCourseCreateUpdateSchema, exports.PromocodePackageCreateUpdateSchema, exports.PromocodeConsultationCreateUpdateSchema], {
    type: 'object',
    discriminator: { propertyName: 'type' },
    required: ['type'],
});
exports.PromocodeDeleteParamsSchema = typebox_1.Type.Pick(exports.PromocodeBaseSchema, ['id']);
exports.PromocodeUpdateParamsSchema = typebox_1.Type.Pick(exports.PromocodeBaseSchema, ['id']);
exports.PromocodeCreateUpdateResponse = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
exports.PromocodeBaseResponceSchema = typebox_1.Type.Pick(exports.PromocodeBaseSchema, ['name', 'usage_count', 'status']);
exports.PromocodeTypeSchema = typebox_1.Type.Object({
    type: PromocodeTypeEnum,
});
exports.PromocodeFindResponseSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(baseEntity_1.BaseEntitySchema, ['updated_at']),
    exports.PromocodeBaseResponceSchema,
    exports.PromocodeTypeSchema,
]);
exports.PromocodeFindListResponseSchema = (0, responses_1.PaginatedList)(exports.PromocodeFindResponseSchema);
exports.PromocodeFilterParamsSchema = typebox_1.Type.Object({
    name: PromocodeNameSchema,
    usage_count: typebox_1.Type.Integer({
        minimum: types_1.PromocodeUsageCountValue.Min,
        maximum: types_1.PromocodeUsageCountValue.Max,
    }),
    start_datetime: PromocodeStartEndDateTimeSchema,
    end_datetime: PromocodeStartEndDateTimeSchema,
    status: PromocodeStatusEnum,
    type: PromocodeTypeEnum,
});
exports.PromocodeOrderParamsScema = typebox_1.Type.Composite([
    typebox_1.Type.Pick(baseEntity_1.BaseEntitySchema, ['created_at']),
    typebox_1.Type.Object({
        usage_count: typebox_1.Type.Integer({
            minimum: types_1.PromocodeUsageCountValue.Min,
            maximum: types_1.PromocodeUsageCountValue.Max,
        }),
    }),
]);
exports.PromocodeFindFilterParamsSchema = typebox_1.Type.Partial(exports.PromocodeFilterParamsSchema);
exports.PromocodeFindOrderParamsSchema = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.PromocodeFindResponseSchema, ['created_at', 'usage_count']));
exports.PromocodeFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.PromocodeFindFilterParamsSchema,
    order: exports.PromocodeFindOrderParamsSchema,
});
exports.PromocodeGetParams = typebox_1.Type.Pick(exports.PromocodeBaseSchema, ['id']);
exports.PromocodeCoursePackageItemSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: typebox_1.Type.String(),
});
exports.PromocodeCourseItemsGetResponseSchema = typebox_1.Type.Object({
    type: typebox_1.Type.Literal(types_1.PromocodeType.Course),
    items: typebox_1.Type.Array(exports.PromocodeCoursePackageItemSchema),
    consultation_type: typebox_1.Type.Null(),
}, { additionalProperties: false });
exports.PromocodePackageItemsGetResponseSchema = typebox_1.Type.Object({
    type: typebox_1.Type.Literal(types_1.PromocodeType.Package),
    items: typebox_1.Type.Array(exports.PromocodeCoursePackageItemSchema),
    consultation_type: typebox_1.Type.Null(),
}, { additionalProperties: false });
exports.PromocodeConsultationItemsGetResponseSchema = typebox_1.Type.Object({
    type: typebox_1.Type.Literal(types_1.PromocodeType.Consultation),
    items: typebox_1.Type.Tuple([]),
    consultation_type: PromocodeConsultationTypeEnum,
}, { additionalProperties: false });
exports.PromocodeCourseGetResponseSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.PromocodeBaseSchema, ['type', 'consultation_type']),
    exports.PromocodeCourseItemsGetResponseSchema,
]);
exports.PromocodePackageGetResponseSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.PromocodeBaseSchema, ['type', 'consultation_type']),
    exports.PromocodePackageItemsGetResponseSchema,
]);
exports.PromocodeConsultationGetResponseSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.PromocodeBaseSchema, ['type', 'consultation_type']),
    exports.PromocodeConsultationItemsGetResponseSchema,
]);
exports.PromocodeGetResponseSchema = (0, utils_1.OneOf)([exports.PromocodeCourseGetResponseSchema, exports.PromocodePackageGetResponseSchema, exports.PromocodeConsultationGetResponseSchema], {
    type: 'object',
    discriminator: { propertyName: 'type' },
    required: ['type'],
});
exports.GetOnePromocodeFieldsShema = exports.PromocodeBaseSchema;
