"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackageNotAttachedUsersSchema = exports.PackageUserSchema = exports.PackageAttachDetachUsersPayloadSchema = exports.PackageUpdateStatusSchema = exports.PackageAdditionalCoursesFindListSchema = exports.PackageAdditionalCourseFindParamsSchema = exports.PackageAdditionalCourseOrderParamsSchema = exports.PackageAdditionalCourseFilterParamsSchema = exports.PackageCoursesFindListSchema = exports.PackageCourseFindParamsSchema = exports.PackageCourseOrderParamsSchema = exports.PackageCourseFilterParamsSchema = exports.PackageWithRelationsSchema = exports.PackageRelationsSchema = exports.PackageAdditionalCoursesPayloadSchema = exports.PackageAdditionalCourseResponseSchema = exports.PackageAdditionalCourseSchema = exports.PackageCoursesPayloadSchema = exports.PackageCourseResponseSchema = exports.PackageCourseSchema = exports.PackageCourseOptionSchema = exports.PaymentOptionFindResponseSchema = exports.PackagePaymentOptionsFindParamsSchema = exports.PackagePaymentOptionsOrderParamsSchema = exports.PackagePaymentOptionsDeletePayloadSchema = exports.PackageParamsSchema = exports.PackageCourseParamsSchema = exports.PackagePaymentOptionParamsSchema = exports.PackageOptionCreateResponseSchema = exports.PaymentOptionCreatePayload = exports.PackagePaymentOptionSchema = exports.PackagePaymentOptionBaseSchema = exports.PackageElementPriceSchema = exports.PackageAccessPeriod = exports.PackageAvatarUrlResponseSchema = exports.PackageAvatarUrlPathSchema = exports.PackageAddUpdateAvatarSchema = exports.PackageCreateUpdateResponseSchema = exports.PackageCreateUpdatePayload = exports.PackageSchema = exports.PackageSpecializations = exports.PackageBaseSchema = exports.PackageTitleSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const common_1 = require("../../common");
const types_1 = require("./types");
const baseEntity_1 = require("../baseEntity");
const responses_1 = require("../../responses");
const package_specialization_1 = require("../../entities/package-specialization");
exports.PackageTitleSchema = typebox_1.Type.String({ minLength: 1, maxLength: 255 });
exports.PackageBaseSchema = typebox_1.Type.Object({
    title: exports.PackageTitleSchema,
    avatar_url_path: (0, common_1.Nullable)(typebox_1.Type.String()),
    description: (0, common_1.Nullable)(typebox_1.Type.String()),
    school_year_id: typebox_1.Type.Integer(),
    form_of_education: typebox_1.Type.Enum(types_1.FormOfEducation),
    use_age_group: typebox_1.Type.Boolean(),
    status: typebox_1.Type.Enum(types_1.PackageStatus, { default: types_1.PackageStatus.Inactive }),
    group: typebox_1.Type.Integer(),
    access_after_payment: typebox_1.Type.Boolean({ default: false }),
    require_documents: typebox_1.Type.Boolean({ default: false }),
    main_course: typebox_1.Type.Boolean({ default: false }),
    additional_courses_limit: typebox_1.Type.Integer({ default: 0 }),
});
exports.PackageSpecializations = typebox_1.Type.Object({
    specializations: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
exports.PackageSchema = typebox_1.Type.Composite([exports.PackageBaseSchema, baseEntity_1.BaseEntitySchema]);
exports.PackageCreateUpdatePayload = typebox_1.Type.Composite([exports.PackageBaseSchema, exports.PackageSpecializations]);
exports.PackageCreateUpdateResponseSchema = typebox_1.Type.Pick(baseEntity_1.BaseEntitySchema, ['id']);
exports.PackageAddUpdateAvatarSchema = typebox_1.Type.Object({
    avatar: common_1.PictureFileSchema,
});
exports.PackageAvatarUrlPathSchema = typebox_1.Type.Object({
    avatar_url_path: (0, common_1.Nullable)(typebox_1.Type.String()),
});
exports.PackageAvatarUrlResponseSchema = exports.PackageAvatarUrlPathSchema;
const PackageStartEndDateTimeSchema = typebox_1.Type.String({
    format: 'date-time',
});
exports.PackageAccessPeriod = typebox_1.Type.Object({
    start: (0, common_1.Nullable)(PackageStartEndDateTimeSchema),
    end: (0, common_1.Nullable)(PackageStartEndDateTimeSchema),
});
exports.PackageElementPriceSchema = typebox_1.Type.Number({
    minimum: 0,
    maximum: 9999999999.99,
    multipleOf: 0.01,
});
exports.PackagePaymentOptionBaseSchema = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        payment_type: typebox_1.Type.Enum(types_1.PaymentDurationType),
        access_duration: typebox_1.Type.Enum(types_1.AccessDurationType),
        duration_days: (0, common_1.Nullable)(typebox_1.Type.Integer({ minimum: 0 })),
        price: exports.PackageElementPriceSchema,
        currency: typebox_1.Type.Enum(types_1.Currency),
    }),
    exports.PackageAccessPeriod,
]);
exports.PackagePaymentOptionSchema = typebox_1.Type.Composite([exports.PackagePaymentOptionBaseSchema, baseEntity_1.BaseEntitySchema]);
exports.PaymentOptionCreatePayload = typebox_1.Type.Object({
    payment_type: typebox_1.Type.Enum(types_1.PaymentDurationType),
    access_duration: typebox_1.Type.Enum(types_1.AccessDurationType),
    period: exports.PackageAccessPeriod,
    duration_days: (0, common_1.Nullable)(typebox_1.Type.Integer({ minimum: 0 })),
    price: exports.PackageElementPriceSchema,
    currency: typebox_1.Type.Enum(types_1.Currency),
});
exports.PackageOptionCreateResponseSchema = typebox_1.Type.Pick(baseEntity_1.BaseEntitySchema, ['id']);
exports.PackagePaymentOptionParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    option_id: typebox_1.Type.Integer(),
});
exports.PackageCourseParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    course_id: typebox_1.Type.Integer(),
});
exports.PackageParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
exports.PackagePaymentOptionsDeletePayloadSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
exports.PackagePaymentOptionsOrderParamsSchema = typebox_1.Type.Composite([typebox_1.Type.Pick(baseEntity_1.BaseEntitySchema, ['created_at'])]);
exports.PackagePaymentOptionsFindParamsSchema = (0, responses_1.FindParams)({
    filters: typebox_1.Type.Object({}, { additionalProperties: false }),
    order: exports.PackagePaymentOptionsOrderParamsSchema,
});
exports.PaymentOptionFindResponseSchema = (0, responses_1.PaginatedList)(exports.PackagePaymentOptionSchema);
exports.PackageCourseOptionSchema = typebox_1.Type.Object({
    level: typebox_1.Type.Enum(types_1.CourseOptionLevel),
    price: exports.PackageElementPriceSchema,
    currency: typebox_1.Type.Enum(types_1.Currency),
});
exports.PackageCourseSchema = typebox_1.Type.Object({
    course_id: typebox_1.Type.Integer(),
    age_group_id: (0, common_1.Nullable)(typebox_1.Type.Integer()),
    options: typebox_1.Type.Array(exports.PackageCourseOptionSchema),
});
exports.PackageCourseResponseSchema = typebox_1.Type.Composite([
    exports.PackageCourseSchema,
    typebox_1.Type.Object({
        course_name: typebox_1.Type.String(),
        age_group_name: (0, common_1.Nullable)(typebox_1.Type.String()),
    }),
]);
exports.PackageCoursesPayloadSchema = typebox_1.Type.Object({
    options: typebox_1.Type.Array(exports.PackageCourseSchema),
});
exports.PackageAdditionalCourseSchema = typebox_1.Type.Object({
    course_id: typebox_1.Type.Integer(),
    price: exports.PackageElementPriceSchema,
    currency: typebox_1.Type.Enum(types_1.Currency),
});
exports.PackageAdditionalCourseResponseSchema = typebox_1.Type.Composite([
    exports.PackageAdditionalCourseSchema,
    typebox_1.Type.Object({
        course_name: typebox_1.Type.String(),
    }),
]);
exports.PackageAdditionalCoursesPayloadSchema = typebox_1.Type.Object({
    courses: typebox_1.Type.Array(exports.PackageAdditionalCourseSchema),
    additional_courses_limit: typebox_1.Type.Integer({ minimum: 0 }),
});
exports.PackageRelationsSchema = typebox_1.Type.Object({
    specializations: typebox_1.Type.Array(package_specialization_1.PackageSpecializationSchema),
    payment_options: typebox_1.Type.Array(exports.PackagePaymentOptionSchema),
    courses: typebox_1.Type.Array(exports.PackageCourseResponseSchema),
    additional_courses: typebox_1.Type.Array(exports.PackageAdditionalCourseResponseSchema),
});
exports.PackageWithRelationsSchema = typebox_1.Type.Composite([exports.PackageSchema, exports.PackageRelationsSchema]);
exports.PackageCourseFilterParamsSchema = typebox_1.Type.Partial(exports.PackageCourseOptionSchema);
exports.PackageCourseOrderParamsSchema = typebox_1.Type.Composite([typebox_1.Type.Pick(baseEntity_1.BaseEntitySchema, ['created_at'])]);
exports.PackageCourseFindParamsSchema = (0, responses_1.FindParams)({
    filters: typebox_1.Type.Object({}, { additionalProperties: false }),
    order: exports.PackageCourseOrderParamsSchema,
});
exports.PackageCoursesFindListSchema = (0, responses_1.PaginatedList)(exports.PackageCourseResponseSchema);
exports.PackageAdditionalCourseFilterParamsSchema = typebox_1.Type.Partial(exports.PackageAdditionalCourseSchema);
exports.PackageAdditionalCourseOrderParamsSchema = typebox_1.Type.Composite([typebox_1.Type.Pick(baseEntity_1.BaseEntitySchema, ['created_at'])]);
exports.PackageAdditionalCourseFindParamsSchema = (0, responses_1.FindParams)({
    filters: typebox_1.Type.Object({}, { additionalProperties: false }),
    order: exports.PackageCourseOrderParamsSchema,
});
exports.PackageAdditionalCoursesFindListSchema = (0, responses_1.PaginatedList)(exports.PackageAdditionalCourseSchema);
exports.PackageUpdateStatusSchema = typebox_1.Type.Pick(exports.PackageBaseSchema, ['status']);
exports.PackageAttachDetachUsersPayloadSchema = typebox_1.Type.Object({
    users: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
exports.PackageUserSchema = typebox_1.Type.Object({
    package_id: typebox_1.Type.Integer(),
    user_id: typebox_1.Type.Integer(),
});
exports.PackageNotAttachedUsersSchema = exports.PackageAttachDetachUsersPayloadSchema;
