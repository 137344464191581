import { useQuery } from '@tanstack/react-query'
import { LOCALIZATION_QUERY_KEYS } from 'src/shared/constants/query.ts'
import { useService } from 'src/shared/hooks/di'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { AxiosError } from 'axios'
import ModuleSpinner from '@repo/ui/ModuleSpinner'
import { Languages, LocalizationAttributes } from 'src/shared/lib/interfaces'
import { LocalizationService } from 'src/shared/services/Localization'
import { LocalizationGuardProps, LocalizationProviderValue } from './types'
import { LocalizationContext } from './context'

const LocalizationGuard = ({ namespace, populate, children }: LocalizationGuardProps) => {
  const localizationService = useService<LocalizationService>(LocalizationService)
  const { i18n } = useTranslation()

  const { isLoading } = useQuery<unknown, AxiosError, Record<Languages, LocalizationAttributes>>({
    queryKey: LOCALIZATION_QUERY_KEYS.namespace(localizationService.locale, namespace),
    queryFn: async () => {
      const namespaces = Array.isArray(namespace) ? namespace : [namespace]
      const locale = localizationService.locale

      const pullCandidates = namespaces.filter((ns) => !i18n.hasResourceBundle(locale, ns))

      if (!pullCandidates.length) {
        if (localizationService.locale !== i18n.language) {
          await i18n.changeLanguage(localizationService.locale)

          return null
        }

        return null
      }

      await Promise.all(
        pullCandidates.map((ns) =>
          localizationService
            .check(ns)
            .then(({ name, externalResponse }) => i18n.addResourceBundle(locale, name, externalResponse)),
        ),
      )

      if (localizationService.locale !== i18n.language) {
        await i18n.changeLanguage(localizationService.locale)
      }

      return null
    },
    refetchOnWindowFocus: false,
  })

  const providerValue = useMemo<LocalizationProviderValue>(
    () => ({
      namespace,
      populate,
    }),
    [namespace, populate],
  )

  return (
    <LocalizationContext.Provider value={providerValue}>
      <>
        {isLoading ? (
          <div className='h-screen'>
            <ModuleSpinner />
          </div>
        ) : (
          children
        )}
      </>
    </LocalizationContext.Provider>
  )
}

export default LocalizationGuard
