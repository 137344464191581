import React, { MutableRefObject, SyntheticEvent, useMemo, useRef, useState } from 'react'
import {
  Calendar as BaseCalendar,
  Event as BaseEvent,
  dateFnsLocalizer,
  DateFormatFunction,
  DateLocalizer,
  Formats,
  View,
} from 'react-big-calendar'
import { format, getDay, parse, startOfWeek, Locale, differenceInDays, addDays } from 'date-fns'
import { useTranslation } from 'react-i18next'
import TopToolbar from './components/TopToolbar'
import MonthDateHeader from './components/MonthDateHeader'
import WeekAndDayEvent from './components/WeekAndDayEvent'
import TimeGutterHeader from './components/TimeGutterHeader'
import WeekDateHeader from './components/WeekDateHeader'
import EventDetailsPopover from './components/EventDetailsPopover'

import 'react-big-calendar/lib/css/react-big-calendar.css'
import '../styles.css'
import { CALENDAR_CONFIG, CALENDAR_FORMATS } from './constants'
import { useDisclosure } from '../hooks/useDisclosure'
import { LookupOption } from 'back-office/src/shared/types/data'
import { CalendarPlanEventsDeletePayloadType } from '@repo/contracts'

export type { BaseEvent }

// TODO: Think about how we will work with expanding event interfaces
export interface ExtendedEvent extends BaseEvent {
  id: number | string
  eventChainId: string
  rrule: string | null
}

type LocalizerOptions = {
  locale?: Locale
}

type DateRangeType = { start: Date; end: Date }

interface CalendarProps<T extends ExtendedEvent> {
  events: T[]
  onRangeChange?: (range: Date[] | DateRangeType, view?: View) => void | undefined
  PopoverContentComponent: React.ComponentType<{
    event: T | null
    onClose: () => void
  }>
  coursesDataOptions?: LookupOption[]
  selectedCourseId?: number
  handleSelectCourse?: (option: LookupOption) => void
  deleteCalendarPlanEventByEventId?: (data: CalendarPlanEventsDeletePayloadType) => Promise<unknown>
  deleteCalendarPlanEventPending?: boolean
  dateRange?: DateRangeType
  view?: View
  onViewChange?: (view: View) => void
}

const Calendar = <T extends ExtendedEvent>({
  events,
  dateRange,
  onRangeChange,
  PopoverContentComponent,
  coursesDataOptions,
  selectedCourseId,
  handleSelectCourse,
  deleteCalendarPlanEventByEventId,
  deleteCalendarPlanEventPending,
  view,
  onViewChange,
}: CalendarProps<T>) => {
  const { t } = useTranslation('calendar')

  const [selectedEvent, setSelectedEvent] = useState<T | null>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const sourceRef = useRef<HTMLElement>()
  const handleSelectEvent = (event: T, e: SyntheticEvent<HTMLElement>) => {
    setSelectedEvent(event)
    sourceRef.current = e.target as HTMLElement
    onOpen()
  }

  const closePopper = () => {
    onClose()
    setSelectedEvent({} as T)
  }

  const localizer = useMemo(
    () =>
      dateFnsLocalizer({
        format: (date: Date, formatString: string, options?: LocalizerOptions) => {
          const formattedDate = format(date, formatString, options)

          const localizedMonth = t(`months_${date.getMonth()}`)
          const localizedDay = t(`weekdays_${date.getDay()}`)

          return formattedDate
            .replace(
              /January|February|March|April|May|June|July|August|September|October|November|December/,
              localizedMonth,
            )
            .replace(/Sunday|Monday|Tuesday|Wednesday|Thursday|Friday|Saturday/, localizedDay)
        },
        parse,
        startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
        getDay,
        locales: {},
      }),
    [t],
  )

  const formats: Partial<Formats> = {
    timeGutterFormat: CALENDAR_FORMATS.TIME,
    eventTimeRangeFormat: () => '',
    dayHeaderFormat: ((date: Date, culture: string | undefined, localizer: DateLocalizer) => {
      const dayName = t(`weekdays_${date.getDay()}`)
      const monthNameGenitive = t(`months_genitive_${date.getMonth()}`)
      const day = localizer.format(date, 'dd', culture)
      return `${dayName}, ${day} ${monthNameGenitive}`
    }) as DateFormatFunction,
  }

  const calculateMidDate = (range: DateRangeType | undefined) => {
    if (!range) {
      return undefined
    }
    const daysDifference = differenceInDays(range.end, range.start)

    return addDays(range.start, Math.floor(daysDifference / 2))
  }

  return (
    <>
      <div className="h-full p-4">
        <BaseCalendar<T>
          localizer={localizer}
          events={events}
          formats={formats}
          startAccessor="start"
          endAccessor="end"
          dayLayoutAlgorithm="no-overlap"
          allDayMaxRows={CALENDAR_CONFIG.allDayMaxRows}
          step={CALENDAR_CONFIG.step}
          timeslots={CALENDAR_CONFIG.timeslots}
          doShowMoreDrillDown={false}
          onSelectEvent={handleSelectEvent}
          popup
          defaultDate={calculateMidDate(dateRange)}
          view={view}
          onView={onViewChange}
          components={{
            toolbar: (props) => (
              <TopToolbar<T>
                {...props}
                coursesDataOptions={coursesDataOptions}
                selectedCourseId={selectedCourseId}
                handleSelectCourse={handleSelectCourse}
              />
            ),
            timeGutterHeader: TimeGutterHeader,
            month: {
              dateHeader: MonthDateHeader,
            },
            week: {
              header: WeekDateHeader,
              event: WeekAndDayEvent,
            },
            day: {
              event: WeekAndDayEvent,
            },
          }}
          onRangeChange={onRangeChange}
          messages={{
            showMore: (count) => `${t('showMore')} ${count}`,
          }}
        />
      </div>
      <EventDetailsPopover
        sourceRef={sourceRef as MutableRefObject<HTMLElement | SVGSVGElement | null>}
        isPopperOpen={isOpen}
        closePopper={closePopper}
        selectedEvent={selectedEvent}
        PopoverContentComponent={PopoverContentComponent}
        deleteCalendarPlanEventByEventId={deleteCalendarPlanEventByEventId}
        deleteCalendarPlanEventPending={deleteCalendarPlanEventPending}
      />
    </>
  )
}

export default Calendar
