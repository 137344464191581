"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompletionCriteria = void 0;
var CompletionCriteria;
(function (CompletionCriteria) {
    CompletionCriteria["Points"] = "points";
    CompletionCriteria["AverageGrade"] = "average_grade";
    CompletionCriteria["TaskCompletion"] = "task_completion";
    CompletionCriteria["LessonCompletion"] = "lesson_completion";
})(CompletionCriteria || (exports.CompletionCriteria = CompletionCriteria = {}));
