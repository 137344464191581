import { inject, injectable, singleton } from 'tsyringe'
import { localizationClient } from 'src/shared/api/clients.ts'
import { stringify } from 'qs'
import i18n from 'i18next'
import { DEFAULT_LANGUAGE, LocalizationKey } from 'src/shared/lib/constants'
import { Languages, LocalizationAttributes, LocalizationResponse } from 'src/shared/lib/interfaces'
import { IndexedDBService } from '../IndexedDB'

@singleton()
@injectable()
export class LocalizationService {
  private _locale: Languages = DEFAULT_LANGUAGE

  constructor(@inject(IndexedDBService) private db: IndexedDBService) {
    this.init()
  }

  init = () => {
    const storageLocale = window.localStorage.getItem(LocalizationKey)

    if (!storageLocale) {
      this._locale = DEFAULT_LANGUAGE
      window.localStorage.setItem(LocalizationKey, this._locale)

      return
    }

    if (i18n.language !== storageLocale) {
      i18n.changeLanguage(storageLocale as Languages)
    }

    this._locale = storageLocale as Languages
  }

  version = async (name: string) => {
    const params = stringify({ locale: this._locale, fields: ['updatedAt'] })
    const response = await localizationClient.get<LocalizationResponse>(`${name}?${params}`)

    return response.data
  }

  check = async (
    name: string,
    populate?: Record<string, string>,
  ): Promise<{
    isActual: boolean
    name: string
    externalResponse: LocalizationAttributes
  }> => {
    const locale = this._locale

    const dbResponse: Record<Languages, LocalizationAttributes> = await this.db.getData(name)
    const localData = dbResponse?.[locale]

    if (!localData) {
      const externalResponse = await this.pull(name, populate)

      return {
        isActual: false,
        name,
        externalResponse,
      }
    }

    const externalResponse = await this.pull(name, populate)

    const isActual = new Date(externalResponse.updatedAt).getTime() === new Date(localData.updatedAt).getTime()

    return {
      isActual,
      name,
      externalResponse,
    }
  }

  pull = async (name: string, populate?: Record<string, string>): Promise<LocalizationAttributes> => {
    const response = await localizationClient.get<LocalizationResponse>(
      `${name}?${stringify(populate)}&locale=${this._locale}`,
    )

    await this.db.storeData(name, { [response.data.data.attributes.locale]: response.data.data.attributes })

    return response.data.data.attributes
  }

  dbGet = async (name: string): Promise<Record<Languages, LocalizationAttributes>> => {
    return this.db.getData(name)
  }

  updateLocale = (language: Languages) => {
    this._locale = language
    window.localStorage.setItem(LocalizationKey, language)
  }

  get locale() {
    return this._locale
  }
}
