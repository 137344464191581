import ROUTES from '../constants'
import { lazy, Suspense } from 'react'
import ModuleSpinner from '@repo/ui/ModuleSpinner'

const UsersListPage = lazy(() => import('src/pages/UsersPage/UsersListPage'))
const ProfilePage = lazy(() => import('src/pages/ProfilePage'))

export default [
  {
    path: ROUTES.USERS.LIST,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <UsersListPage />
      </Suspense>
    ),
    isScrollable: false,
  },
  {
    path: ROUTES.USERS.PROFILE,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <ProfilePage />
      </Suspense>
    ),
  },
]
