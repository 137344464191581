import ROUTES from '../constants'
import { lazy, Suspense } from 'react'
import ModuleSpinner from '@repo/ui/ModuleSpinner'

const PositionsListPage = lazy(() => import('src/pages/PositionsPage/PositionsListPage'))
const PositionEditPage = lazy(() => import('src/pages/PositionsPage/PositionEditPage'))

export default [
  {
    path: ROUTES.POSITIONS.LIST,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <PositionsListPage />
      </Suspense>
    ),
    isScrollable: false,
  },
  {
    path: ROUTES.POSITIONS.EDIT,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <PositionEditPage />
      </Suspense>
    ),
    isScrollable: false,
  },
]
