import { FC } from 'react'
import { PrivateLayout } from 'src/components/Layouts/PrivateLayout'
import { useLocation, matchPath } from 'react-router-dom'
import { RouteDefinition } from 'src/app/router'

export const PrivateLayoutRouterWrapper: FC<{ routes: RouteDefinition[] }> = ({ routes }) => {
  const location = useLocation()

  const currentRoute = routes.find((route) => matchPath(route.path, location.pathname))
  const isScrollable = currentRoute?.isScrollable ?? true

  return <PrivateLayout isScrollable={isScrollable} />
}
