import UIPrivateLayout from '@repo/ui/PrivateLayout'
import { Outlet, useNavigate } from 'react-router-dom'
import ModuleSpinner from '@repo/ui/ModuleSpinner'
import useAuthRedirect from 'src/shared/hooks/useAuthRedirect.ts'
import { useCallback } from 'react'
import { Menu } from 'src/components/Menu/Menu'
import useFetchPermissions from 'src/shared/hooks/useFetchPermissions.ts'
import { observer } from 'mobx-react'
import { AbilityProvider } from 'src/shared/context/AbilityProvider.tsx'
import useApiInterceptors from 'src/shared/hooks/useApiInterceptors'
import Logo from 'src/components/Layouts/PrivateLayout/components/Logo'
import SideBarLanguageSelect from 'src/components/Layouts/PrivateLayout/components/SideBarLanguageSelect'
import { NotificationsMenuItemButton } from 'src/components/Layouts/PrivateLayout/components/NotificationsMenuItemButton'
import UserCard from 'src/components/Layouts/PrivateLayout/components/UserCard'
import ROUTES from 'src/app/router/constants'
import useFetchMe from 'src/modules/UsersModule/User/hooks/useFetchMe'
import { clsx } from 'clsx'

interface PrivateLayoutProps {
  isScrollable?: boolean
}

export const PrivateLayout = observer(({ isScrollable = true }: PrivateLayoutProps) => {
  useApiInterceptors()

  const { isLoading: isMeLoading } = useFetchMe()
  const navigate = useNavigate()

  const isPermissionsLoading = useFetchPermissions()

  const emptyStoreCallback = useCallback(() => {
    navigate(ROUTES.AUTH.SIGN_IN, { replace: true })
  }, [navigate])

  const isLocalStorageScanning = useAuthRedirect({
    emptyStoreCallback,
  })

  if (isLocalStorageScanning || isPermissionsLoading || isMeLoading) {
    return (
      <div className='flex min-h-screen grow flex-col items-center justify-center'>
        <ModuleSpinner />
      </div>
    )
  }

  return (
    <AbilityProvider>
      <UIPrivateLayout>
        <UIPrivateLayout.Aside>
          <div className='flex h-full flex-col'>
            <Logo />
            <Menu />
            <div className='mt-auto'>
              <NotificationsMenuItemButton />
            </div>
            <div className='mt-2'>
              <SideBarLanguageSelect />
            </div>
            <div className='mt-2'>
              <UserCard />
            </div>
          </div>
        </UIPrivateLayout.Aside>
        <div
          className={clsx(
            {
              'overflow-y-auto': isScrollable,
            },
            'my-4 flex grow flex-col overflow-x-auto',
          )}
        >
          <div className='mr-4 grow rounded-lg bg-white'>
            <Outlet />
          </div>
        </div>
      </UIPrivateLayout>
    </AbilityProvider>
  )
})
